import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Form from "react-bootstrap/Form";
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const YourJobStatusDetail = () => {
	const navigate = useNavigate();

	const [YourIncome, setYourIncome] = useState('');
	const [BonusesCommissions, setBonusesCommissions] = useState('');
	const [InterestIncome, setInterestIncome] = useState('');
	const [DividendIncome, setDividendIncome] = useState('');
	const [CapitalGains, setCapitalGains] = useState('');
	const [PartnershipIncome, setPartnershipIncome] = useState('');
	const [OtherInvestmentIncome, setOtherInvestmentIncome] = useState('');
	const [OtherIncome, setOtherIncome] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		data.preventDefault();
		window.scrollTo({ top: 0, behavior: 'smooth' });
		console.log({ data });
		const dataSave = {
			YourIncome: YourIncome || 0,
			BonusesCommissions: BonusesCommissions || 0,
			InterestIncome: InterestIncome || 0,
			DividendIncome: DividendIncome || 0,
			CapitalGains: CapitalGains || 0,
			PartnershipIncome: PartnershipIncome || 0,
			OtherInvestmentIncome: OtherInvestmentIncome || 0,
			OtherIncome: OtherIncome || 0,
		};
		await updateApplication(createApplicationDefault, { YourJobStatusDetail: dataSave });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/your-job-expense');
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			.toString()
			.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });

		setYourIncome(getApplicationDefault.YourJobStatusDetail.YourIncome);
		setBonusesCommissions(getApplicationDefault.YourJobStatusDetail.BonusesCommissions);
		setInterestIncome(getApplicationDefault.YourJobStatusDetail.InterestIncome);
		setDividendIncome(getApplicationDefault.YourJobStatusDetail.DividendIncome);
		setCapitalGains(getApplicationDefault.YourJobStatusDetail.CapitalGains);
		setPartnershipIncome(getApplicationDefault.YourJobStatusDetail.PartnershipIncome);
		setOtherInvestmentIncome(getApplicationDefault.YourJobStatusDetail.OtherInvestmentIncome);
		setOtherIncome(getApplicationDefault.YourJobStatusDetail.OtherIncome);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const numberFormatDesign = (e) => {
		let value = e.target.value;
		if (value) {
			value = value.replace(/\D/g, '');
			value = parseFloat(value);
			value = value.toLocaleString('en-US');
		} else {
			value = 0;
		}
		if (value === 'NaN') {
			value = 0;
		}
		return value;
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell us about your income?</h2>
				<form onSubmit={onSubmit}>
					<Row className='py-5'>
						<Col>
							<label>Your Income</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setYourIncome(sanitizeValue(e.target.value))}
								value={parseCurrency(YourIncome)}
							/>
						</Col>
						<Col>
							<label>Bonuses & Commissions?</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setBonusesCommissions(sanitizeValue(e.target.value))}
								value={parseCurrency(BonusesCommissions)}
							/>
						</Col>
						<Col>
							<label>Interest Income</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setInterestIncome(sanitizeValue(e.target.value))}
								value={parseCurrency(InterestIncome)}
							/>
						</Col>
						<Col>
							<label>Dividend Income</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setDividendIncome(sanitizeValue(e.target.value))}
								value={parseCurrency(DividendIncome)}
							/>
						</Col>
						<Col>
							<label>Capital Gains</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setCapitalGains(sanitizeValue(e.target.value))}
								value={parseCurrency(CapitalGains)}
							/>
						</Col>
					</Row>
					<Row className='py-3 justify-content-md-center'>
						<Col xs={3}>
							<label>Partnership Income</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setPartnershipIncome(sanitizeValue(e.target.value))}
								value={parseCurrency(PartnershipIncome)}
							/>
						</Col>
						<Col xs={4}>
							<label>Other Investment Income (Non Real Estate)</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setOtherInvestmentIncome(sanitizeValue(e.target.value))}
								value={parseCurrency(OtherInvestmentIncome)}
							/>
						</Col>
						<Col xs={3}>
							<label>Other Income</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setOtherIncome(sanitizeValue(e.target.value))}
								value={parseCurrency(OtherIncome)}
							/>
						</Col>
					</Row>

					{/* <Row>
            <Col className="py-5 text-center">
              <Link to="/dashboard/your-job-status" className="cl-btn btn-2">Back</Link>
              <button type="submit" className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}
					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/your-job-status' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
