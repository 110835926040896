import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const ProfitPension = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [arr, setArr] = useState([]);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		// Convert arr to the format of data. Using arr here becuase data is raw and arr is sanitized
		// arr is sanitized in updateInput function
		const saveData = {};
		arr.map((item, i) => {
			saveData[i] = item;
		});
		console.log({ saveData });
		updateApplication(createApplicationDefault, { ProfitPension: saveData });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/payable-loan-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setArr(Object.values(getApplicationDefault.ProfitPension));
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const addInput = () => {
		setArr((s) => {
			return [
				...s,
				[
					{
						NameInstitutionPlan: '',
						acct: '',
						AccountBalance: '',
						AmountTotallyVested: '',
						Loans: '',
					},
				],
			];
		});
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};
	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const updateInput = (evnt, index, ele) => {
		const { value } = evnt.target;
		const list = [...arr];
		if (ele === 'AccountBalance' || ele === 'AmountTotallyVested' || ele === 'Loans')
			list[index][ele] = sanitizeValue(value);
		else list[index][ele] = value;
		setArr(list);
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell us about your Retirement Accounts</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{arr.map((item, i) => {
						return (
							<Row key={'ProfitPension_' + i}>
								<Row className='py-3'>
									<Col xs={6}>
										<label>Name of Institution or Plan</label>
										<input
											value={item.NameInstitutionPlan}
											type='text'
											{...register(i + '.NameInstitutionPlan')}
											onChange={(e) => updateInput(e, i, 'NameInstitutionPlan')}
										/>
										{errors[i] && errors[i].NameInstitutionPlan && (
											<span>Name of Institution or Plan is required*</span>
										)}
									</Col>
									<Col>
										<label>Type of Acct</label>
										<select
											defaultValue={item.acct}
											onChange={(e) => updateInput(e, i, 'acct')}
											{...register(i + '.acct')}>
											<option value=''>Choose Type of Acct</option>
											<option value='Profit Sharing'>Profit Sharing</option>
											<option value='Pension IRA'>Pension IRA</option>
											<option value='401K'>401K</option>
											<option value='403B'>403B</option>
										</select>
										{errors[i] && errors[i].acct && <span>Account Balance is required*</span>}
									</Col>
									<Col>
										<label>Account Balance ($)</label>
										<input
											value={parseCurrency(item.AccountBalance)}
											type='text'
											{...register(i + '.AccountBalance')}
											onChange={(e) => updateInput(e, i, 'AccountBalance')}
										/>
										{errors[i] && errors[i].AccountBalance && (
											<span>Account Balance is required*</span>
										)}
									</Col>
								</Row>
								<Row className='py-3 justify-content-md-center'>
									<Col xs={3}>
										<label>Amount Totally Vested ($)</label>
										<input
											value={parseCurrency(item.AmountTotallyVested)}
											type='text'
											{...register(i + '.AmountTotallyVested')}
											onChange={(e) => updateInput(e, i, 'AmountTotallyVested')}
										/>
										{errors[i] && errors[i].AmountTotallyVested && (
											<span>Amount Totally Vested is required*</span>
										)}
									</Col>

									<Col xs={3}>
										<label>Loans ($)</label>
										<input
											value={parseCurrency(item.Loans)}
											type='text'
											{...register(i + '.Loans')}
											onChange={(e) => updateInput(e, i, 'Loans')}
										/>
										{errors[i] && errors[i].Loans && <span>Loans is required*</span>}
									</Col>
								</Row>
							</Row>
						);
					})}

					<Row className='py-5 justify-content-md-center'>
						<Col xs={3} className='text-center '>
							<div onClick={addInput} className='cl-btn'>
								+
							</div>
						</Col>
					</Row>

					{/* <Row>
            <Col className="text-center py-5">
              <Link to="/dashboard/profit-pension-flow" className="btn-outline-primary mr-3">Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}

					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/profit-pension-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
