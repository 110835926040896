import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';
// import { Link } from "react-router-dom";

export const InsurancePolicy = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [arr, setArr] = useState([
		{
			InsuranceCompany: '',
			Insured: '',
			Beneficiary: '',
			FaceValue: '',
			CashValue: '',
			Loans: '',
		},
	]);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data, arr });

		updateApplication(createApplicationDefault, { InsurancePolicy: arr });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/mortgage-contract-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setArr(Object.values(getApplicationDefault.InsurancePolicy));
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const addInput = () => {
		setArr((prevArr) => [
			...prevArr,
			{
				InsuranceCompany: '',
				Insured: '',
				Beneficiary: '',
				FaceValue: '',
				CashValue: '',
				Loans: '',
			},
		]);
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};
	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const updateInput = (evnt, index, ele) => {
		const { name, value } = evnt.target;
		const updatedArr = arr.map((item, i) => {
			if (i === index) {
				if (ele === 'CashValue' || ele === 'Loans') {
					return {
						...item,
						[ele]: sanitizeValue(value),
					};
				} else
					return {
						...item,
						[ele]: value,
					};
			}
			return item;
		});
		setArr(updatedArr);
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell us about your Life Insurance</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{arr.map((item, i) => {
						return (
							<Row>
								<Row className='py-5'>
									<Col xs={4}>
										<label>Insurance Company</label>
										<input
											value={item.InsuranceCompany}
											type='text'
											{...register(i + '.InsuranceCompany')}
											onChange={(e) => updateInput(e, i, 'InsuranceCompany')}
										/>
										{errors[i] && errors[i].InsuranceCompany && (
											<span>Insurance Company is required*</span>
										)}
									</Col>
									<Col xs={4}>
										<label>Insured</label>
										<input
											value={item.Insured}
											type='text'
											{...register(i + '.Insured')}
											onChange={(e) => updateInput(e, i, 'Insured')}
										/>
										{errors[i] && errors[i].Insured && <span>Insured is required*</span>}
									</Col>
									<Col xs={4}>
										<label>Beneficiary</label>
										<input
											value={item.Beneficiary}
											type='text'
											{...register(i + '.Beneficiary')}
											onChange={(e) => updateInput(e, i, 'Beneficiary')}
										/>
										{errors[i] && errors[i].Insured && <span>Beneficiary is required*</span>}
									</Col>
								</Row>
								<Row className='mb'>
									<Col xs={4}>
										<label>Face Value of Policy</label>
										<input
											value={item.FaceValue}
											type='text'
											{...register(i + '.FaceValue')}
											onChange={(e) => updateInput(e, i, 'FaceValue')}
										/>
										{errors[i] && errors[i].FaceValue && (
											<span>Face Value of Policy is required*</span>
										)}
									</Col>
									<Col xs={4}>
										<label>Cash Value of Policy ($)</label>
										<input
											value={parseCurrency(item.CashValue)}
											type='text'
											{...register(i + '.CashValue')}
											onChange={(e) => updateInput(e, i, 'CashValue')}
											placeholder='0'
										/>
										{errors[i] && errors[i].CashValue && (
											<span>Cash Value of Policy is required*</span>
										)}
									</Col>
									<Col xs={4}>
										<label>Loans ($)</label>
										<input
											value={parseCurrency(item.Loans)}
											type='text'
											{...register(i + '.Loans')}
											onChange={(e) => updateInput(e, i, 'Loans')}
											placeholder='0'
										/>
										{errors[i] && errors[i].Loans && <span>Loans is required*</span>}
									</Col>
								</Row>
							</Row>
						);
					})}

					<Row className='py-5 justify-content-md-center'>
						<Col xs={3} className='text-center '>
							<div onClick={addInput} className='cl-btn'>
								+
							</div>
						</Col>
					</Row>

					{/* <Row>
            <Col className="text-center">
              <Link to="/dashboard/insurance-policy-flow" className="cl-btn">Back</Link>
              <button className="cl-btn">Submit & Continue</button>
            </Col>
          </Row> */}
					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/insurance-policy-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
