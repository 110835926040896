import React, { useEffect } from 'react';
const Temp = () => {
	const docusignClientId = 'bf19f4da-a7da-4480-89f0-f211c9f67f90';
	const docusignRedirectUri = 'https://www.google.com';
	// const docusignRedirectUri = 'http://localhost:3000';
	const docusignAuthorizationEndpoint = 'https://account-d.docusign.com/oauth/auth';
	const docusignScope = 'signature';
	const docusignResponseType = 'token';

	const redirectToDocuSignAuth = () => {
		// const authUrl = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=${docusignClientId}&redirect_uri=${docusignRedirectUri}&code_challenge_method=S256&code_challenge=${codeChallenge}`;
		const authUrl = `${docusignAuthorizationEndpoint}?response_type=token&client_id=${docusignClientId}&redirect_uri=${docusignRedirectUri}&scope=${docusignScope}`;
		window.location.href = authUrl;
		const urlParams = new URLSearchParams(window.location.search);
		const authorizationCode = urlParams.get('code');
		console.log({ authorizationCode });
	};

	const accountId = 'YOUR_DOCUSIGN_ACCOUNT_ID';
	const accessToken = 'YOUR_ACCESS_TOKEN'; // Obtain the access token using OAuth

	const docusignAPIBaseURL = 'https://demo.docusign.net/restapi/v2.1'; // Replace with the appropriate base URL

	async function sendDocumentForSigning() {
		const requestBody = {
			recipients: {
				signers: [
					{
						email: 'recipient1@example.com',
						name: 'Recipient 1',
						roleName: 'Signer',
						routingOrder: '1',
					},
					// Add more signers or other recipients as needed
				],
			},
			emailSubject: 'Please sign this document',
			emailBlurb: 'Hi, Please review and sign the document attached.',
			documents: [
				{
					documentId: '1',
					name: 'Document.pdf', // Replace with your document's name
					documentBase64: 'BASE64_ENCODED_DOCUMENT_CONTENT', // Replace with your document's Base64 encoded content
				},
			],
			status: 'sent', // Set to 'sent' to send the envelope immediately
		};

		try {
			const response = await fetch(`${docusignAPIBaseURL}/accounts/${accountId}/envelopes`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requestBody),
			});

			if (response.ok) {
				const data = await response.json();
				console.log('Envelope sent successfully', data);
			} else {
				console.error('Failed to send envelope', response);
			}
		} catch (error) {
			console.error('Error while sending envelope', error);
		}
	}

	useEffect(() => {
		redirectToDocuSignAuth();
		// Call the function to send the document for signing
		sendDocumentForSigning();
	}, []);

	return <div>Temp</div>;
};

// import React, { useState } from 'react';
// import axios from 'axios';
// const docusign = require("docusign-esign");

// const Temp = () => {
// 	const [accessToken, setAccessToken] = useState('');
// 	const [envelopeId, setEnvelopeId] = useState('');

// 	const authenticate = async () => {
// 		try {
// 			// const response = await axios.post(
// 			// 	'https://account-d.docusign.com/oauth/token',
// 			// 	`grant_type=client_credentials&client_id=9dbe9f3a-c433-4d96-ac16-f3dc1637a312&client_secret=658069e6-74ee-4af8-81d8-b7cd5f4c451e`,
// 			// 	{
// 			// 		headers: {
// 			// 			'Content-Type': 'application/x-www-form-urlencoded',
// 			// 		},
// 			// 	}
// 			// );

// 			// const response = await axios.get(
// 			// 	'https://account-d.docusign.com/oauth/auth?' +
// 			// 		`response_type=token&client_id=9dbe9f3a-c433-4d96-ac16-f3dc1637a312&redirect_uri=https://www.google.com&scope=signature`,
// 			// 	{
// 			// 		headers: {
// 			// 			'Content-Type': 'application/x-www-form-urlencoded',
// 			// 		},
// 			// 	}
// 			// );

// //   let dsApiClient = new docusign.ApiClient();
// //     dsApiClient.setBasePath("https://account-d.docusign.com/oauth/auth");
// //     let scopes = ["signature"];
// //     let redirectURI = "https://www.google.com";
// //     let responseType = "token";
// //     let clientSecret = "658069e6-74ee-4af8-81d8-b7cd5f4c451e";
// //     let clientId = "9dbe9f3a-c433-4d96-ac16-f3dc1637a312";

// 			setAccessToken(response.data.access_token);
// 		} catch (error) {
// 			console.error('Authentication error:', error);
// 		}
// 	};

// 	const sendDocumentForSignature = async () => {
// 		try {
// 			const response = await axios.post(
// 				'https://demo.docusign.net/restapi/v2/accounts/YOUR_ACCOUNT_ID/envelopes',
// 				{
// 					documents: [
// 						{
// 							documentBase64: 'BASE64_ENCODED_DOCUMENT_CONTENT',
// 							name: 'Resume 5.O.pdf',
// 							fileExtension: 'pdf',
// 							documentId: '1',
// 						},
// 					],
// 					recipients: {
// 						signers: [
// 							{
// 								email: 'shameekh2002@gmail.com',
// 								name: 'Recipient Name',
// 								recipientId: '1',
// 								routingOrder: '1',
// 							},
// 						],
// 					},
// 					status: 'sent',
// 				},
// 				{
// 					headers: {
// 						'Content-Type': 'application/json',
// 						Authorization: `Bearer ${accessToken}`,
// 					},
// 				}
// 			);

// 			setEnvelopeId(response.data.envelopeId);
// 		} catch (error) {
// 			console.error('Sending document error:', error);
// 		}
// 	};

// 	return (
// 		<div>
// 			<h1>DocuSign Integration Example</h1>
// 			<button onClick={authenticate}>Authenticate</button>
// 			<button onClick={sendDocumentForSignature}>Send Document for Signature</button>
// 			{envelopeId && <p>Envelope ID: {envelopeId}</p>}
// 		</div>
// 	);
// };

export default Temp;
