import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const RealEstateInvestment = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [arr, setArr] = useState([
		/*
    {
      LegalOwner: "",
      PropertyAddress: "",
      PurchasePrice: "",
      PurchaseYear: "",
      MarketValue: "",
      PresentLoanBal: "",
      InterestRate: "",
      LoanMaturityDate: "",
      MonthlyRentalIncome: "",
      MonthlyPayment: "",
      Lender: "",
      InvestmentOwnedSomeone: "",
      InvestmentOwnedSomeoneOwnership: "",
    }
    */
	]);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		// Convert arr to the format of data. Using arr here becuase data is raw and arr is sanitized
		// arr is sanitized in updateInput function
		const saveData = {};
		arr.map((item, i) => {
			saveData[i] = item;
		});
		console.log({ saveData });

		await updateApplication(createApplicationDefault, { RealEstateInvestment: saveData });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/real-estate-second-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setArr(Object.values(getApplicationDefault.RealEstateInvestment));
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const addInput = () => {
		setArr((s) => {
			return [
				...s,
				[
					{
						LegalOwner: '',
						PropertyAddress: '',
						PurchasePrice: '',
						PurchaseYear: '',
						MarketValue: '',
						PresentLoanBal: '',
						InterestRate: '',
						LoanMaturityDate: '',
						MonthlyRentalIncome: '',
						MonthlyPayment: '',
						Lender: '',
						InvestmentOwnedSomeone: '',
						InvestmentOwnedSomeoneOwnership: '',
					},
				],
			];
		});
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		// return value
	};
	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const updateInput = (evnt, index, ele) => {
		const { name, value } = evnt.target;
		const list = [...arr];
		if (
			ele === 'MonthlyRentalIncome' ||
			ele === 'MonthlyPayment' ||
			ele === 'PurchasePrice' ||
			ele === 'MarketValue' ||
			ele === 'PresentLoanBal'
		)
			list[index][ele] = sanitizeValue(value);
		else list[index][ele] = value;
		setArr(list);
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Provide list of investment properties owned</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{arr.map((item, i) => {
						return (
							<Row key={i}>
								<Row className='py-3'>
									<Col xs={3}>
										<label>Legal Owner</label>
										<input
											value={item.LegalOwner}
											type='text'
											{...register(i + '.LegalOwner')}
											onChange={(e) => updateInput(e, i, 'LegalOwner')}
										/>
									</Col>
									<Col xs={6}>
										<label>Property Address</label>
										<input
											value={item.PropertyAddress}
											type='text'
											{...register(i + '.PropertyAddress')}
											onChange={(e) => updateInput(e, i, 'PropertyAddress')}
										/>
									</Col>
									<Col>
										<label>Purchase Price</label>
										<input
											value={parseCurrency(item.PurchasePrice)}
											type='text'
											{...register(i + '.PurchasePrice')}
											onChange={(e) => updateInput(e, i, 'PurchasePrice')}
										/>
									</Col>
									<Col>
										<label>Purchase Year</label>
										<input
											value={item.PurchaseYear}
											type='text'
											{...register(i + '.PurchaseYear')}
											onChange={(e) => updateInput(e, i, 'PurchaseYear')}
											maxlength='2'
										/>
									</Col>
								</Row>
								<Row className='py-3'>
									<Col>
										<label>Market Value</label>
										<input
											value={parseCurrency(item.MarketValue)}
											type='text'
											{...register(i + '.MarketValue')}
											onChange={(e) => updateInput(e, i, 'MarketValue')}
										/>
									</Col>
									<Col>
										<label>Present Loan Bal. $</label>
										<input
											value={parseCurrency(item.PresentLoanBal)}
											type='text'
											{...register(i + '.PresentLoanBal')}
											onChange={(e) => updateInput(e, i, 'PresentLoanBal')}
										/>
									</Col>
									<Col>
										<label>Interest Rate</label>
										<input
											value={item.InterestRate}
											type='text'
											{...register(i + '.InterestRate')}
											onChange={(e) => updateInput(e, i, 'InterestRate')}
										/>
									</Col>
									<Col>
										<label>Loan Maturity Date</label>
										<input
											value={item.LoanMaturityDate}
											type='text'
											{...register(i + '.LoanMaturityDate')}
											onChange={(e) => updateInput(e, i, 'LoanMaturityDate')}
										/>
									</Col>
								</Row>
								<Row className='py-3 justify-content-md-center'>
									<Col xs={3}>
										<label>Monthly Rental Income</label>
										<input
											value={parseCurrency(item.MonthlyRentalIncome)}
											type='text'
											{...register(i + '.MonthlyRentalIncome')}
											onChange={(e) => updateInput(e, i, 'MonthlyRentalIncome')}
										/>
									</Col>
									<Col xs={3}>
										<label xs={3}>Monthly Payment(s)</label>
										<input
											value={parseCurrency(item.MonthlyPayment)}
											type='text'
											{...register(i + '.MonthlyPayment')}
											onChange={(e) => updateInput(e, i, 'MonthlyPayment')}
										/>
									</Col>
									<Col xs={3}>
										<label>Lender</label>
										<input
											value={item.Lender}
											type='text'
											{...register(i + '.Lender')}
											onChange={(e) => updateInput(e, i, 'Lender')}
										/>
									</Col>
								</Row>
								<Row className='py-3 justify-content-md-center'>
									<Col xs={4}>
										<label>investment property is owned with someone else</label>
										{['radio'].map((type, index) => (
											<div key={`InvestmentOwnedSomeone_${i}_${index}`} className='mb-3 py-3'>
												<Form.Check
													inline
													label='Yes'
													type={type}
													id={`InvestmentOwnedSomeone1_${i}_${index}`}
													value='Yes'
													{...register(`${i}.InvestmentOwnedSomeone`)}
													onChange={(e) => updateInput(e, i, 'InvestmentOwnedSomeone')}
													defaultChecked={item.InvestmentOwnedSomeone === 'Yes'}
												/>
												<Form.Check
													inline
													label='No'
													type={type}
													id={`InvestmentOwnedSomeone2_${i}_${index}`}
													value='No'
													{...register(`${i}.InvestmentOwnedSomeone`)}
													onChange={(e) => updateInput(e, i, 'InvestmentOwnedSomeone')}
													defaultChecked={item.InvestmentOwnedSomeone === 'No'}
												/>
											</div>
										))}
									</Col>
									<Col xs={4}>
										<label>Enter the % you own of this property</label>
										<input
											value={
												item.InvestmentOwnedSomeone === 'No'
													? '100'
													: item.InvestmentOwnedSomeoneOwnership
											}
											type='text'
											{...register(i + '.InvestmentOwnedSomeoneOwnership')}
											onChange={(e) => updateInput(e, i, 'InvestmentOwnedSomeoneOwnership')}
										/>
									</Col>
								</Row>
							</Row>
						);
					})}

					<Row className='py-5 justify-content-md-center'>
						<Col xs={3} className='text-center '>
							<div onClick={addInput} className='cl-btn'>
								+
							</div>
						</Col>
					</Row>

					{/*
          <Row className="py-5 justify-content-md-center">
            <Col xs={3}>
              <b>
                <h4 className="text-center font-bold secondary">Total Valuation: $1245</h4>
              </b>
            </Col>
          </Row>
          */}

					{/* <Row>
            <Col className="text-center">
              <Link to="/dashboard/real-estate-investment-flow" className="cl-btn">Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}

					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/real-estate-investment-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
