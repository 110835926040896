import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const ContingentLiabilitiesQuestionnaire = () => {
	const {
		register,
		handleSubmit,
		// watch,
		// formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [applicantDeclaredBankruptcy, setApplicantDeclaredBankruptcy] = useState('');
	const [coApplicantDeclaredBankruptcy, setCoApplicantDeclaredBankruptcy] = useState('');
	const [applicantAssetsPledged, setApplicantAssetsPledged] = useState('');
	const [coApplicantAssetsPledged, setCoApplicantAssetsPledged] = useState('');
	const [applicantDrawnWill, setApplicantDrawnWill] = useState('');
	const [coApplicantDrawnWill, setCoApplicantDrawnWill] = useState('');
	const [applicantFinancialPlan, setApplicantFinancialPlan] = useState('');
	const [coApplicantFinancialPlan, setCoApplicantFinancialPlan] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		const saveData = {
			ApplicantDeclaredBankruptcy: applicantDeclaredBankruptcy,
			CoApplicantDeclaredBankruptcy: coApplicantDeclaredBankruptcy,
			ApplicantAssetsPledged: applicantAssetsPledged,
			CoApplicantAssetsPledged: coApplicantAssetsPledged,
			ApplicantDrawnWill: applicantDrawnWill,
			CoApplicantDrawnWill: coApplicantDrawnWill,
			ApplicantFinancialPlan: applicantFinancialPlan,
			CoApplicantFinancialPlan: coApplicantFinancialPlan,
		};

		await updateApplication(createApplicationDefault, { ContingentLiabilitiesQuestionnaire: saveData });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/contingent-liabilities-questionnaire-other/');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setApplicantDeclaredBankruptcy(
			getApplicationDefault.ContingentLiabilitiesQuestionnaire.ApplicantDeclaredBankruptcy
		);
		setCoApplicantDeclaredBankruptcy(
			getApplicationDefault.ContingentLiabilitiesQuestionnaire.CoApplicantDeclaredBankruptcy
		);
		setApplicantAssetsPledged(getApplicationDefault.ContingentLiabilitiesQuestionnaire.ApplicantAssetsPledged);
		setCoApplicantAssetsPledged(getApplicationDefault.ContingentLiabilitiesQuestionnaire.CoApplicantAssetsPledged);
		setApplicantDrawnWill(getApplicationDefault.ContingentLiabilitiesQuestionnaire.ApplicantDrawnWill);
		setCoApplicantDrawnWill(getApplicationDefault.ContingentLiabilitiesQuestionnaire.CoApplicantDrawnWill);
		setApplicantFinancialPlan(getApplicationDefault.ContingentLiabilitiesQuestionnaire.ApplicantFinancialPlan);
		setCoApplicantFinancialPlan(getApplicationDefault.ContingentLiabilitiesQuestionnaire.CoApplicantFinancialPlan);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	return (
		<div className='body-bg py-5'>
			<Container>
				<form onSubmit={handleSubmit(onSubmit)}>
					<h2 className='step-head-blue pulse text-center py-3'>Contingent Liabilities Questionaire...</h2>
					<Row className='py-2 '>
						<Col xs={7}>
							<h5 className='py-2 font-bold py-5'>
								Have you or any firm in which you were a major owner ever declared bankruptcy or had a
								judgement against you?
							</h5>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Applicant</h6>
							{['radio'].map((type) => (
								<div key={`ApplicantDeclaredBankruptcy_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`ApplicantDeclaredBankruptcy_1`}
										{...register('.ApplicantDeclaredBankruptcy')}
										onChange={(e) => setApplicantDeclaredBankruptcy(e.target.value)}
										checked={applicantDeclaredBankruptcy === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`ApplicantDeclaredBankruptcy_2`}
										{...register('.ApplicantDeclaredBankruptcy')}
										onChange={(e) => setApplicantDeclaredBankruptcy(e.target.value)}
										checked={applicantDeclaredBankruptcy === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Co-Applicant</h6>
							{['radio'].map((type) => (
								<div key={`CoApplicantDeclaredBankruptcy_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`CoApplicantDeclaredBankruptcy_1`}
										{...register('.CoApplicantDeclaredBankruptcy')}
										onChange={(e) => setCoApplicantDeclaredBankruptcy(e.target.value)}
										checked={coApplicantDeclaredBankruptcy === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`CoApplicantDeclaredBankruptcy_2`}
										{...register('.CoApplicantDeclaredBankruptcy')}
										onChange={(e) => setCoApplicantDeclaredBankruptcy(e.target.value)}
										checked={coApplicantDeclaredBankruptcy === 'No'}
									/>
								</div>
							))}
						</Col>
					</Row>
					<Row className='py-2 '>
						<Col xs={7}>
							<h5 className='py-2 font-bold py-5 '>
								Are any assets pledged or debts secured except as shown?
							</h5>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Applicant</h6>
							{['radio'].map((type) => (
								<div key={`ApplicantAssetsPledged_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`ApplicantAssetsPledged _1`}
										{...register('.ApplicantAssetsPledged')}
										onChange={(e) => setApplicantAssetsPledged(e.target.value)}
										checked={applicantAssetsPledged === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`ApplicantAssetsPledged_2`}
										{...register('.ApplicantAssetsPledged')}
										onChange={(e) => setApplicantAssetsPledged(e.target.value)}
										checked={applicantAssetsPledged === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Co-Applicant</h6>
							{['radio'].map((type) => (
								<div key={`CoApplicantAssetsPledged_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`CoApplicantAssetsPledged_1`}
										{...register('.CoApplicantAssetsPledged')}
										onChange={(e) => setCoApplicantAssetsPledged(e.target.value)}
										checked={coApplicantAssetsPledged === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`CoApplicantAssetsPledged_2`}
										{...register('.CoApplicantAssetsPledged')}
										onChange={(e) => setCoApplicantAssetsPledged(e.target.value)}
										checked={coApplicantAssetsPledged === 'No'}
									/>
								</div>
							))}
						</Col>
					</Row>

					<Row className='py-2 '>
						<Col xs={7}>
							<h5 className='py-2 font-bold py-5'>Have you drawn a will?</h5>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Applicant</h6>
							{['radio'].map((type) => (
								<div key={`ApplicantDrawnWill_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`ApplicantDrawnWill _1`}
										{...register('.ApplicantDrawnWill')}
										onChange={(e) => setApplicantDrawnWill(e.target.value)}
										checked={applicantDrawnWill === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`ApplicantDrawnWill_2`}
										{...register('.ApplicantDrawnWill')}
										onChange={(e) => setApplicantDrawnWill(e.target.value)}
										checked={applicantDrawnWill === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Co-Applicant</h6>
							{['radio'].map((type) => (
								<div key={`CoApplicantDrawnWill_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`CoApplicantDrawnWill_1`}
										{...register('.CoApplicantDrawnWill')}
										onChange={(e) => setCoApplicantDrawnWill(e.target.value)}
										checked={coApplicantDrawnWill === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`CoApplicantDrawnWill_2`}
										{...register('.CoApplicantDrawnWill')}
										onChange={(e) => setCoApplicantDrawnWill(e.target.value)}
										checked={coApplicantDrawnWill === 'No'}
									/>
								</div>
							))}
						</Col>
					</Row>

					<Row className='py-2 '>
						<Col xs={7}>
							<h5 className='py-2 font-bold py-5'>
								Have you ever had a financial plan prepared for you?
							</h5>
						</Col>
						<Col className='text-center my-5'>
							<h6 className='font-bold'>Applicant</h6>
							{['radio'].map((type) => (
								<div key={`ApplicantFinancialPlan_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`ApplicantFinancialPlan_1`}
										{...register('.ApplicantFinancialPlan')}
										onChange={(e) => setApplicantFinancialPlan(e.target.value)}
										checked={applicantFinancialPlan === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`ApplicantFinancialPlan_1`}
										{...register('.ApplicantFinancialPlan')}
										onChange={(e) => setApplicantFinancialPlan(e.target.value)}
										checked={applicantFinancialPlan === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5'>
							<h6 className='font-bold'>Co-Applicant</h6>
							{['radio'].map((type) => (
								<div key={`inline-${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`CoApplicantFinancialPlan_1`}
										{...register('.CoApplicantFinancialPlan')}
										onChange={(e) => setCoApplicantFinancialPlan(e.target.value)}
										checked={coApplicantFinancialPlan === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`CoApplicantFinancialPlan_1`}
										{...register('.CoApplicantFinancialPlan')}
										onChange={(e) => setCoApplicantFinancialPlan(e.target.value)}
										checked={coApplicantFinancialPlan === 'No'}
									/>
								</div>
							))}
						</Col>
					</Row>

					{/* <Row>
            <Col className="py-4 text-center">
              <Link to="/dashboard/contingent-liabilities" className="cl-btn"> Back </Link>
              <input className="cl-btn" type="submit" value="Save & Continue" />
            </Col>
          </Row> */}

					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/contingent-liabilities' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
