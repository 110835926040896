import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

export const ShareBondFlow = () => {
	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-gray pulse text-center py-3'>Do you own any Stocks, Bonds or Securities?</h2>
				<Row className='py-5 justify-content-md-center'>
					<Col xs={3} className='text-center'>
						<Link to='/dashboard/share-bond'>
							<button className='cl-btn-blue big'>Yes, I do</button>
						</Link>
					</Col>
					<Col xs={3} className='text-center'>
						<Link to='/dashboard/cash-saving'>
							<button className='cl-btn-gray big'>No, I don't</button>
						</Link>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
