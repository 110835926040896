import React, { useEffect, useState } from 'react';
import './../Dashboard.css';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/AccordionItem';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getAuth } from 'firebase/auth';

import AppIcon from './../../assets/img/application-icon.png';
import { getApplications, deleteApplication, getApplicationsSliced } from './../../firebase';
import { set } from 'react-hook-form';

function convertStringIntoDate(dateString) {
	const dateObj = new Date(dateString);

	// Convert to PST timezone
	const pstDateObj = new Date(dateObj.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));

	const options = {
		year: 'numeric',
		month: 'long',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	};

	// Format the date
	const formattedDate = pstDateObj.toLocaleString('en-US', options);

	// Add suffix for the day
	const dayWithSuffix = formattedDate.replace(/(\d+)(st|nd|rd|th)/, (_, day) => `${day}${getDaySuffix(day)}`);

	return dayWithSuffix;
}

// Helper function to get day suffix
function getDaySuffix(day) {
	if (day % 10 === 1 && day !== 11) {
		return 'st';
	} else if (day % 10 === 2 && day !== 12) {
		return 'nd';
	} else if (day % 10 === 3 && day !== 13) {
		return 'rd';
	} else {
		return 'th';
	}
}

export default function Application({ records, setRecords }) {
	const [show, setShow] = useState(false);
	const [warning, setWarning] = useState('');
	const [deleteID, setDeleteID] = useState(''); // This is the ID of the record to be deleted. Pass it to the modal

	const navigate = async (url, props) => {
		let pdfData = props.record;
		// console.log({ pdfData })
		localStorage.setItem('pdfData', JSON.stringify(pdfData));
		localStorage.setItem('pdfDataID', pdfData.id);
		// localStorage.setItem("pdfDataID", props.record.id);

		// console.log("navigate pdfData", pdfData);
		// console.log("navigate pdfData.id", pdfData.id);

		localStorage.setItem('createApplicationDefault', props.record.id);

		// console.log("navigate createApplicationDefault", props.record.id);

		if (pdfData.last_loc) {
			url = pdfData.last_loc;
		}
		if (pdfData.status === 'COMPLETED') {
			url = '/dashboard/transactiontype/';
		}
		// setTimeout(() => {
		window.open(url, '_blank');
		// }, 1000);
	};

	const navigate_edit = async (url, props) => {
		let pdfData = props.record;
		localStorage.setItem('pdfData', JSON.stringify(pdfData));
		localStorage.setItem('pdfDataID', pdfData.id);

		const auth = getAuth();
		const user = auth.currentUser;
		localStorage.setItem('user', JSON.stringify(user));
		// console.log({ user, auth })

		localStorage.setItem('createApplicationDefault', props.record.id);
		// console.log("navigate_edit createApplicationDefault", props.record.id);

		setTimeout(() => {
			window.open(url);
		}, 1000);
	};

	const docusign = async (url, props) => {
		localStorage.setItem('createApplicationDefault', props.record.id);

		window.open(url);
	};

	// const [navigateShowHide, setNavigateShowHide] = useState(false);

	const Record = (props) => {
		const [message, setMessage] = useState(true);
		return props.record.status === 'COMPLETED' ? (
			<Col xs={3} key={props.record.id}>
				<div className='application-container application-complete'>
					<div className='d-flex justify-content-end'>
						<button
							type='button'
							className='btn-close'
							aria-label='Close'
							onClick={props.showDeleteModal}
							style={{
								backgroundImage: 'url(/trash.png)',
								backgroundSize: 'cover',
							}}></button>
					</div>
					<div>
						<div className='text-center py-2'>
							<img src={AppIcon} width='50px' alt='APPLICATION COMPLETED' />
						</div>
						<h6 className='text-center font-bold'>APPLICATION ID:</h6>
						<h6 className='date text-center font-normal'>#{props.record.id}</h6>
						<p className='font-complete py-2 text-center font-bold'>COMPLETED</p>
						<p className='date text-center font-normal'>
							<b>Submited:</b> {convertStringIntoDate(props.record.updated)}
						</p>
						<p
							className='round-outline-preview date py-2 font-bold text-center font-normal'
							onClick={() => navigate('/dashboard/transactiontype/', props)}>
							Edit Application
						</p>
						<Dropdown onToggle={() => setMessage(!message)}>
							<Dropdown.Toggle
								as={CustomToggle}
								variant='success'
								message={message}
								setMessage={setMessage}
								id='dropdown-basic'
							/>
							<Dropdown.Menu>
								<Dropdown.Item
									className='my-2 date font-bold text-center font-normal'
									onClick={() => navigate_edit('/pdf/', props)}>
									Original PFS - CL
								</Dropdown.Item>
								<Dropdown.Item
									className='my-2 date font-bold text-center font-normal'
									onClick={() => navigate_edit('/BusinessPurposePDF/', props)}>
									Business Purpose Loan Certification LLC - CL
								</Dropdown.Item>
								<Dropdown.Item
									className='my-2 date font-bold text-center font-normal'
									onClick={() => navigate_edit('/BankingDisclosurePDF/', props)}>
									E- Banking disclosure - CL
								</Dropdown.Item>
								<Dropdown.Item
									className='my-2 date font-bold text-center font-normal'
									onClick={() => navigate_edit('/BusinessLoanPDF/', props)}>
									Business loan checklist - CL
								</Dropdown.Item>
								<Dropdown.Item
									className='my-2 date font-bold text-center font-normal'
									onClick={() => navigate_edit('/AuthorizationPDF/', props)}>
									Authorization - CL
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<p
							onClick={() => window.open('https://creativelending.securefilepro.com/portal/#/login')}
							className='round-outline-preview date py-2 px-1 font-bold text-center font-normal none'>
							Secure Document Upload
						</p>
						{/* <p className="round-outline-preview date py-2 font-bold text-center font-normal" onClick={() => docusign("https://account-d.docusign.com/oauth/auth?response_type=code&scope=user_read user_write organization_read account_read group_read permission_read identity_provider_read domain_read&client_id=a967f334-4b6c-4946-b83d-ca25436ae6d1&redirect_uri=http://localhost:3000/react-oauth-docusign/build", props)}>Docusign</p> */}
					</div>
				</div>
			</Col>
		) : (
			<Col xs={3} key={props.record.id}>
				<div className='application-pending application-container'>
					<div className='d-flex justify-content-end'>
						<button
							type='button'
							className='btn-close'
							aria-label='Close'
							onClick={props.showDeleteModal}
							style={{
								backgroundImage: 'url(/trash.png)',
								backgroundSize: 'cover',
							}}></button>
					</div>
					<div>
						<div className='text-center py-2'>
							<img src={AppIcon} width='50px' alt='APPLICATION PENDING' />
						</div>
						<h6 className='text-center font-bold'>APPLICATION ID:</h6>
						<h6 className='date text-center font-normal'>#{props.record.id}</h6>
						<p className='font-pending py-2 text-center font-bold'>PENDING</p>
						<p className='date text-center font-normal'>
							<b>Last Edited:</b> {convertStringIntoDate(props.record.updated)}
						</p>
						<p
							className='round-outline-preview date py-2 font-bold text-center font-normal'
							onClick={() => navigate('/dashboard/transactiontype/', props)}>
							Edit Application
						</p>
						{/* <p className="round-outline-preview date py-2 font-bold text-center font-normal" onClick={() => navigate_edit("/pdf/", props)}>Preview Application</p> */}
					</div>
				</div>
			</Col>
		);
	};

	// This method will delete a record
	const showDeleteModal = (id) => {
		setWarning('Are you sure you want to delete this record?');
		setDeleteID(id);
		setShow(true);
	};

	// This following section will display the table with the records of individuals.
	return (
		<>
			{records.map((record) => {
				return <Record record={record} showDeleteModal={() => showDeleteModal(record.id)} key={record.id} />;
			})}
			<Warning
				show={show}
				setShow={setShow}
				text={warning}
				setText={setWarning}
				records={records}
				setRecords={setRecords}
				deleteID={deleteID}
				setDeleteID={setDeleteID}
			/>
		</>
	);
}

const CustomToggle = React.forwardRef(({ onClick, message, setMessage }, ref) => (
	<button
		className='round-outline-preview date py-2 font-bold text-center font-normal w-100 d-flex justify-content-end gap-4 pe-4 t align-items-center'
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}>
		{/* {children} */}
		<div>{message ? 'Preview Application' : 'Hide Application'}</div>
		<div>&#x25bc;</div>
	</button>
));

const Warning = ({ show, setShow, text, setText, setRecords, records, deleteID, setDeleteID }) => {
	const [loading, setLoading] = useState(false);
	const handleClose = () => {
		setShow(false);
		setText('');
		setDeleteID('');
	};

	const handleDelete = async () => {
		try {
			setLoading(true);
			await deleteApplication(deleteID);
			const newRecords = records.filter((el) => el.id !== deleteID);
			setRecords(newRecords);
		} catch (error) {
			console.log({ error });
			alert('Error deleting record');
		} finally {
			handleClose();
			setLoading(false);
		}
	};

	return (
		<Modal
			show={show}
			onHide={() => setShow(false)}
			backdrop='static'
			keyboard={false}
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Header closeButton>
				<Modal.Title>Warning</Modal.Title>
			</Modal.Header>
			<Modal.Body>{text}</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={handleClose} disabled={loading}>
					Close
				</Button>
				<Button variant='primary' onClick={handleDelete} disabled={loading}>
					{!loading && 'Delete It'}
					{loading && <div class='loader'></div>}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
