import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const CompleteForm = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [valueCompaniesCF, setValueCompaniesCF] = useState('');
	const [valueCompaniesAmountCF, setValueCompaniesAmountCF] = useState('');
	const [automobilesCF, setAutomobilesCF] = useState('');
	const [automobilesAmountCF, setAutomobilesAmountCF] = useState('');
	const [otherLiabilitiesCF, setOtherLiabilitiesCF] = useState('');
	const [otherLiabilitiesAmountCF, setOtherLiabilitiesAmountCF] = useState('');
	const [loansAgainstRetirementCF, setLoansAgainstRetirementCF] = useState('');
	const [loansAgainstRetirementAmountCF, setLoansAgainstRetirementAmountCF] = useState('');
	const [taxesFederalStateCF, setTaxesFederalStateCF] = useState('');
	const [taxesFederalStateAmountCF, setTaxesFederalStateAmountCF] = useState('');
	const [installmentLoansContractsCF, setInstallmentLoansContractsCF] = useState('');
	const [installmentLoansContractsAmountCF, setInstallmentLoansContractsAmountCF] = useState('');
	const [shortTermNotesCF, setShortTermNotesCF] = useState('');
	const [shortTermNotesAmountCF, setShortTermNotesAmountCF] = useState('');
	const [otherTangibleAssetsCF, setOtherTangibleAssetsCF] = useState('');
	const [otherTangibleAssetsAmountCF, setOtherTangibleAssetsAmountCF] = useState('');
	const [personalPropertyCF, setPersonalPropertyCF] = useState('');
	const [personalPropertyAmountCF, setPersonalPropertyAmountCF] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		// data["status"] = "COMPLETED";
		console.log({ data });
		const saveData = {
			valueCompanies: valueCompaniesCF,
			valueCompaniesAmount: valueCompaniesAmountCF,
			automobiles: automobilesCF,
			automobilesAmount: automobilesAmountCF,
			otherLiabilities: otherLiabilitiesCF,
			otherLiabilitiesAmount: otherLiabilitiesAmountCF,
			loansAgainstRetirement: loansAgainstRetirementCF,
			loansAgainstRetirementAmount: loansAgainstRetirementAmountCF,
			taxesFederalState: taxesFederalStateCF,
			taxesFederalStateAmount: taxesFederalStateAmountCF,
			installmentLoansContracts: installmentLoansContractsCF,
			installmentLoansContractsAmount: installmentLoansContractsAmountCF,
			shortTermNotes: shortTermNotesCF,
			shortTermNotesAmount: shortTermNotesAmountCF,
			otherTangibleAssets: otherTangibleAssetsCF,
			otherTangibleAssetsAmount: otherTangibleAssetsAmountCF,
			personalProperty: personalPropertyCF,
			personalPropertyAmount: personalPropertyAmountCF,
		};

		await updateApplication(createApplicationDefault, { status: 'COMPLETED', CompleteForm: saveData });
		// Firebase Store, Create new Record and Store Data

		// setTimeout(() => {
		navigate('/dashboard/');
		// }, 3000);
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });

		setValueCompaniesCF(getApplicationDefault.CompleteForm.valueCompanies);
		setValueCompaniesAmountCF(getApplicationDefault.CompleteForm.valueCompaniesAmount);
		setAutomobilesCF(getApplicationDefault.CompleteForm.automobiles);
		setAutomobilesAmountCF(getApplicationDefault.CompleteForm.automobilesAmount);
		setOtherLiabilitiesCF(getApplicationDefault.CompleteForm.otherLiabilities);
		setOtherLiabilitiesAmountCF(getApplicationDefault.CompleteForm.otherLiabilitiesAmount);
		setLoansAgainstRetirementCF(getApplicationDefault.CompleteForm.loansAgainstRetirement);
		setLoansAgainstRetirementAmountCF(getApplicationDefault.CompleteForm.loansAgainstRetirementAmount);
		setTaxesFederalStateCF(getApplicationDefault.CompleteForm.taxesFederalState);
		setTaxesFederalStateAmountCF(getApplicationDefault.CompleteForm.taxesFederalStateAmount);
		setInstallmentLoansContractsCF(getApplicationDefault.CompleteForm.installmentLoansContracts);
		setInstallmentLoansContractsAmountCF(getApplicationDefault.CompleteForm.installmentLoansContractsAmount);
		setShortTermNotesCF(getApplicationDefault.CompleteForm.shortTermNotes);
		setShortTermNotesAmountCF(getApplicationDefault.CompleteForm.shortTermNotesAmount);
		setOtherTangibleAssetsCF(getApplicationDefault.CompleteForm.otherTangibleAssets);
		setOtherTangibleAssetsAmountCF(getApplicationDefault.CompleteForm.otherTangibleAssetsAmount);
		setPersonalPropertyCF(getApplicationDefault.CompleteForm.personalProperty);
		setPersonalPropertyAmountCF(getApplicationDefault.CompleteForm.personalPropertyAmount);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};
	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Almost Done!</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>Value of My Companies (List)</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={valueCompaniesCF} setter={setValueCompaniesCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('valueCompaniesAmount')}
								onChange={(e) => setValueCompaniesAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(valueCompaniesAmountCF)}
							/>
						</Col>
					</Row>

					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>Automobiles (Describe)</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={automobilesCF} setter={setAutomobilesCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('automobilesAmount')}
								onChange={(e) => setAutomobilesAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(automobilesAmountCF)}
							/>
						</Col>
					</Row>

					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>Personal Property</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={personalPropertyCF} setter={setPersonalPropertyCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('personalPropertyAmount')}
								onChange={(e) => setPersonalPropertyAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(personalPropertyAmountCF)}
							/>
						</Col>
					</Row>

					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>Other Tangible Assets (Describe)</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={otherTangibleAssetsCF} setter={setOtherTangibleAssetsCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('otherTangibleAssetsAmount')}
								onChange={(e) => setOtherTangibleAssetsAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(otherTangibleAssetsAmountCF)}
							/>
						</Col>
					</Row>

					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>Short Term Notes Due to Others</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={shortTermNotesCF} setter={setShortTermNotesCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('shortTermNotesAmount')}
								onChange={(e) => setShortTermNotesAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(shortTermNotesAmountCF)}
							/>
						</Col>
					</Row>

					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>Installment Loans and Contracts</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={installmentLoansContractsCF} setter={setInstallmentLoansContractsCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('installmentLoansContractsAmount')}
								onChange={(e) => setInstallmentLoansContractsAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(installmentLoansContractsAmountCF)}
							/>
						</Col>
					</Row>

					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>Taxes (Federal & State)</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={taxesFederalStateCF} setter={setTaxesFederalStateCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('taxesFederalStateAmount')}
								onChange={(e) => setTaxesFederalStateAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(taxesFederalStateAmountCF)}
							/>
						</Col>
					</Row>

					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>
								Loans Against Retirement Accounts (401K, IRA, etc.) Schedule 6
							</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={loansAgainstRetirementCF} setter={setLoansAgainstRetirementCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('loansAgainstRetirementAmount')}
								onChange={(e) => setLoansAgainstRetirementAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(loansAgainstRetirementAmountCF)}
							/>
						</Col>
					</Row>

					<Row className='py-4'>
						<Col>
							<h5 className='py-4 font-bold questionair'>Other Liabilities (Describe)</h5>
						</Col>
						<Col className='questionair d-flex align-items-center justify-content-evenly'>
							<Sole_Joint checker={otherLiabilitiesCF} setter={setOtherLiabilitiesCF} />
						</Col>
						<Col className='questionair'>
							<h6 className='font-bold'>Amount ($)</h6>
							<input
								type='text'
								{...register('otherLiabilitiesAmount')}
								onChange={(e) => setOtherLiabilitiesAmountCF(sanitizeValue(e.target.value))}
								value={parseCurrency(otherLiabilitiesAmountCF)}
							/>
						</Col>
					</Row>

					<Row>
						<Col className='py-4 text-center'>
							<input className='cl-btn' type='submit' value='Finish and Submit' />
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};

const Sole_Joint = ({ checker, setter }) => {
	const checkedStyle = {
		background: '#82bdc5',
		color: '#fff',
	};
	const uncheckedStyle = {
		background: '#fff',
		color: '#82bdc5',
		border: '1px solid #82bdc5',
	};
	return (
		<>
			<button
				type='button'
				// className={`btn btn-sm`}
				className='btn'
				style={checker === 'joint' ? checkedStyle : uncheckedStyle}
				onClick={() => setter('joint')}>
				Joint-J
			</button>
			<div>
				<button
					type='button'
					className='btn'
					style={checker === 'sole' ? checkedStyle : uncheckedStyle}
					onClick={() => setter('sole')}>
					Sole-S
				</button>
			</div>
		</>
	);
};
