import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const CoAplicantLocation = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [streetAddressCAL, setStreetAddressCAL] = useState('');
	const [cityCAL, setCityCAL] = useState('');
	const [stateCAL, setStateCAL] = useState('');
	const [zipCAL, setZipCAL] = useState('');
	const [countryCAL, setCountryCAL] = useState('');
	const [phoneCAL, setPhoneCAL] = useState('');
	const [sinceCAL, setSinceCAL] = useState('');
	const [rentCAL, setRentCAL] = useState('');
	const [propertyTypeCAL, setPropertyTypeCAL] = useState('');
	const [sameAddress, setSameAddress] = useState(false);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		console.log({ data });
		const saveData = {
			streetAddress: streetAddressCAL,
			city: cityCAL,
			state: stateCAL,
			zip: zipCAL,
			country: countryCAL,
			phone: phoneCAL,
			since: sinceCAL,
			rent: rentCAL,
			propertyType: propertyTypeCAL,
			sameAddress: sameAddress,
		};
		await updateApplication(createApplicationDefault, { CoAplicantLocation: saveData });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/co-aplicant-working-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });

		setStreetAddressCAL(getApplicationDefault.CoAplicantLocation.streetAddress);
		setCityCAL(getApplicationDefault.CoAplicantLocation.city);
		setStateCAL(getApplicationDefault.CoAplicantLocation.state);
		setZipCAL(getApplicationDefault.CoAplicantLocation.zip);
		setCountryCAL(getApplicationDefault.CoAplicantLocation.country);
		setPhoneCAL(getApplicationDefault.CoAplicantLocation.phone);
		setSinceCAL(getApplicationDefault.CoAplicantLocation.since);
		setRentCAL(getApplicationDefault.CoAplicantLocation.rent);
		setPropertyTypeCAL(getApplicationDefault.CoAplicantLocation.propertyType);
		setSameAddress(getApplicationDefault.CoAplicantLocation.sameAddress);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	useEffect(() => {
		const populateData = async () => {
			const getApplicationDefault = await getApplication(createApplicationDefault);
			setStreetAddressCAL(getApplicationDefault.YourLocation.StreetAddress);
			setCityCAL(getApplicationDefault.YourLocation.City);
			setStateCAL(getApplicationDefault.YourLocation.State);
			setZipCAL(getApplicationDefault.YourLocation.Zip);
			setCountryCAL(getApplicationDefault.YourLocation.Country);
			setPhoneCAL(getApplicationDefault.YourLocation.PhoneNumber);
			setSinceCAL(getApplicationDefault.YourLocation.SinceYear);
			setRentCAL(getApplicationDefault.YourLocation.MonthlyPayment);
			setPropertyTypeCAL(getApplicationDefault.YourLocation.PropertyType);
		};
		if (sameAddress) populateData();
	}, [sameAddress]);

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-gray pulse text-center py-3'>What is your Co-Applicant address?</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row className='py-5'>
						<Col xs={12} md={6}>
							<label className='form-check-label' htmlFor='propertyStatus'>
								Check here if you have the same address as your co-applicant
							</label>
							<input
								className='form-check-input mx-5'
								type='checkbox'
								role='switch'
								{...register('sameAddress')}
								checked={sameAddress}
								onChange={(e) => setSameAddress(!sameAddress)}
							/>
						</Col>
					</Row>
					<Row className='py-3 justify-content-md-center'>
						<Col>
							<label>Street Address</label>
							<input
								type='text'
								disabled={sameAddress}
								{...register('.streetAddress')}
								onChange={(e) => setStreetAddressCAL(e.target.value)}
								value={streetAddressCAL}
							/>
							{errors && errors.streetAddress && <span>Street Address is required</span>}
						</Col>
						<Col>
							<label>City</label>
							<input
								type='text'
								disabled={sameAddress}
								{...register('.city')}
								onChange={(e) => setCityCAL(e.target.value)}
								value={cityCAL}
							/>
							{errors && errors.city && <span>City is required</span>}
						</Col>
						<Col>
							<label>State</label>
							<select
								disabled={sameAddress}
								{...register('state')}
								onChange={(e) => setStateCAL(e.target.value)}
								value={stateCAL}>
								<option value='AL'>Alabama</option>
								<option value='AK'>Alaska</option>
								<option value='AZ'>Arizona</option>
								<option value='AR'>Arkansas</option>
								<option value='CA'>California</option>
								<option value='CO'>Colorado</option>
								<option value='CT'>Connecticut</option>
								<option value='DE'>Delaware</option>
								<option value='DC'>District of Columbia</option>
								<option value='FL'>Florida</option>
								<option value='GA'>Georgia</option>
								<option value='HI'>Hawaii</option>
								<option value='ID'>Idaho</option>
								<option value='IL'>Illinois</option>
								<option value='IN'>Indiana</option>
								<option value='IA'>Iowa</option>
								<option value='KS'>Kansas</option>
								<option value='KY'>Kentucky</option>
								<option value='LA'>Louisiana</option>
								<option value='ME'>Maine</option>
								<option value='MD'>Maryland</option>
								<option value='MA'>Massachusetts</option>
								<option value='MI'>Michigan</option>
								<option value='MN'>Minnesota</option>
								<option value='MS'>Mississippi</option>
								<option value='MO'>Missouri</option>
								<option value='MT'>Montana</option>
								<option value='NE'>Nebraska</option>
								<option value='NV'>Nevada</option>
								<option value='NH'>New Hampshire</option>
								<option value='NJ'>New Jersey</option>
								<option value='NM'>New Mexico</option>
								<option value='NY'>New York</option>
								<option value='NC'>North Carolina</option>
								<option value='ND'>North Dakota</option>
								<option value='OH'>Ohio</option>
								<option value='OK'>Oklahoma</option>
								<option value='OR'>Oregon</option>
								<option value='PA'>Pennsylvania</option>
								<option value='RI'>Rhode Island</option>
								<option value='SC'>South Carolina</option>
								<option value='SD'>South Dakota</option>
								<option value='TN'>Tennessee</option>
								<option value='TX'>Texas</option>
								<option value='UT'>Utah</option>
								<option value='VT'>Vermont</option>
								<option value='VA'>Virginia</option>
								<option value='WA'>Washington</option>
								<option value='WV'>West Virginia</option>
								<option value='WI'>Wisconsin</option>
								<option value='WY'>Wyoming</option>
								<option value='AS'>American Samoa</option>
								<option value='GU'>Guam</option>
								<option value='MP'>Northern Mariana Islands</option>
								<option value='PR'>Puerto Rico</option>
								<option value='UM'>United States Minor Outlying Islands</option>
								<option value='VI'>Virgin Islands</option>
								<option value='NA'>Not a US City</option>
							</select>
							{errors && errors.state && <span>State is Required</span>}
						</Col>

						<Col>
							<label>Zip*</label>
							<input
								type='text'
								disabled={sameAddress}
								{...register('.zip')}
								onChange={(e) => setZipCAL(e.target.value)}
								value={zipCAL}
							/>
							{errors && errors.zip && <span>Zipcode is required</span>}
						</Col>
					</Row>
					<Row className='py-3 justify-content-md-center'>
						<Col xs={2}>
							<label>Country</label>
							<input
								type='text'
								disabled={sameAddress}
								{...register('.country')}
								onChange={(e) => setCountryCAL(e.target.value)}
								value={countryCAL}
							/>
							{errors && errors.country && <span>Country is required</span>}
						</Col>
						<Col xs={2}>
							<label>Phone Number</label>
							<input
								type='text'
								disabled={sameAddress}
								{...register('.phone')}
								onChange={(e) => setPhoneCAL(e.target.value)}
								value={phoneCAL}
							/>
							{errors && errors.phone && <span>Phone # is required</span>}
						</Col>
						<Col xs={2}>
							<label>Since what year?</label>
							<input
								type='text'
								disabled={sameAddress}
								{...register('.since')}
								onChange={(e) => setSinceCAL(e.target.value)}
								value={sinceCAL}
							/>
							{errors && errors.since && <span>Since what year is required</span>}
						</Col>
						<Col xs={2}>
							<label>Do you own or rent?</label>
							{['radio'].map((type) => (
								<div key={`inline-${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='own'
										value='own'
										type={type}
										id={`inline-${type}-1`}
										{...register('propertyType')}
										onChange={(e) => setPropertyTypeCAL(e.target.value)}
										checked={propertyTypeCAL === 'own'}
										disabled={sameAddress}
									/>
									<Form.Check
										inline
										label='rent'
										value='rent'
										type={type}
										id={`inline-${type}-2`}
										{...register('propertyType')}
										onChange={(e) => setPropertyTypeCAL(e.target.value)}
										checked={propertyTypeCAL === 'rent'}
										disabled={sameAddress}
									/>
								</div>
							))}
							{errors && errors.propertyType && <span>Property Status Required</span>}
						</Col>
						{propertyTypeCAL === 'rent' ? (
							<Col xs={2}>
								<label>Monthly Payment</label>
								<input
									type='text'
									disabled={sameAddress}
									{...register('.rent')}
									onChange={(e) => setRentCAL(e.target.value)}
									value={rentCAL}
								/>
								{errors && errors.rent && <span>Monthly Payment is required</span>}
							</Col>
						) : (
							''
						)}
					</Row>

					{/* <Row className="justify-content-md-center row">
            <Col xs={3} className="text-center ">
              <Link className="cl-btn" to="/dashboard/about-your-co-Aplicant"> Back </Link>
              <input className="cl-btn" type="submit" value="Save & Continue" />
            </Col>
          </Row> */}

					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/about-your-co-Aplicant' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
