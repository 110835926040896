import React from 'react';
import './Header.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Logo from '../../assets/img/creativelendinglogo.png';
import { Outlet } from 'react-router-dom';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../../firebase';

export const Header = ({ setShow }) => {
	const navigate = useNavigate();

	const logout_btn = () => {
		logout();
		setTimeout(() => {
			navigate('/login');
		}, 500);
	};

	return (
		<div className='header-bg'>
			<Container>
				<Row>
					<Col>
						<div className='logo-container'>
							<Link to='/dashboard'>
								<img src={Logo} />
							</Link>
						</div>
					</Col>
					<Col>
						<div className='menu-container'>
							<Nav className='justify-content-end me-auto' activeKey='/dashboard'>
								<Nav.Item>
									<Nav.Link href='/dashboard'>Dashboard</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link href='/dashboard/intro'>Apply Now</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									{/* <Nav.Link> */}
									<p
										className='nav-link'
										style={{ color: '#fff', cursor: 'pointer' }}
										// href={`mailto:support@nayyerdev.com`}
										onClick={() => setShow(true)}>
										Report bug
									</p>
									{/* </Nav.Link> */}
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey='link-1' onClick={logout_btn}>
										Logout
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
	<Outlet />;
};
