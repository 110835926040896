import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AddIcon from '../../../assets/img/add-template.png';
import AppIcon from '../../../assets/img/application-icon.png';
import Application from './../Application';
import { Link } from 'react-router-dom';
import { hover } from '@testing-library/user-event/dist/hover';
import { getApplicationsSliced } from '../../../firebase';

export const AllTemplates = () => {
	const [records, setRecords] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getApplicationsSliced().then((res) => {
			setRecords(res);
		});
	}, []);
	const fetchMore = async () => {
		const lastRecord = records[records.length - 1];
		setLoading(true);
		try {
			const newRecords = await getApplicationsSliced(lastRecord);
			if (newRecords.length === 0) {
				alert('No more records to load');
				return;
			}
			setRecords([...records, ...newRecords]);
		} catch (error) {
			alert('Error fetching more records');
			console.log({ error });
		} finally {
			setLoading(false);
		}
	};
	return (
		<Container>
			<Row className='py-5 my-3 g-4'>
				<Application records={records} setRecords={setRecords} />
				<Col xs={3}>
					<Link to='/dashboard/intro'>
						<div className='application-container'>
							<div className='text-center'>
								<img src={AddIcon} width='130px' />
								<h6 className='text-center font-bold'>NEW APPLICATION</h6>
							</div>
						</div>
					</Link>
				</Col>
			</Row>
			{/* Load more applications */}
			<div className='d-flex justify-content-center align-items center'>
				<button
					type='button'
					className='btn btn-primary border border-secondary'
					style={{ background: '#82bdc5', color: '#fff' }}
					onClick={fetchMore}
					disabled={loading}>
					{!loading && 'Load More'}
					{loading && <div class='loader'></div>}
				</button>
			</div>
		</Container>
	);
};
