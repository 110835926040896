import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Form from "react-bootstrap/Form";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const YourJobStatus = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [WhoYourEmployer, setWhoYourEmployer] = useState('');
	const [WhatPositionTitle, setWhatPositionTitle] = useState('');
	const [WhenDidYouStart, setWhenDidYouStart] = useState('');
	const [StreetAddress, setStreetAddress] = useState('');
	const [City, setCity] = useState('');
	const [State, setState] = useState('');
	const [Zip, setZip] = useState('');
	const [WorkPhone, setWorkPhone] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		// console.log("onSubmit data", data);
		console.log({ data });
		const dataSave = {
			WhoYourEmployer: WhoYourEmployer,
			WhatPositionTitle: WhatPositionTitle,
			WhenDidYouStart: WhenDidYouStart,
			StreetAddress: StreetAddress,
			City: City,
			Zip: Zip,
			WorkPhone: WorkPhone,
		};

		// console.log("updateApplication id, data", createApplicationDefault, data);
		await updateApplication(createApplicationDefault, { YourJobStatus: dataSave });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/your-job-status-detail');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		// console.log("YourJobStatus getApplicationDefault", getApplicationDefault);

		setWhoYourEmployer(getApplicationDefault.YourJobStatus.WhoYourEmployer);
		setWhatPositionTitle(getApplicationDefault.YourJobStatus.WhatPositionTitle);
		setWhenDidYouStart(getApplicationDefault.YourJobStatus.WhenDidYouStart);
		setStreetAddress(getApplicationDefault.YourJobStatus.StreetAddress);
		setCity(getApplicationDefault.YourJobStatus.City);
		setState(getApplicationDefault.YourJobStatus.State);
		setZip(getApplicationDefault.YourJobStatus.Zip);
		setWorkPhone(getApplicationDefault.YourJobStatus.WorkPhone);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	return (
		<div className='body-bg py-5'>
			<Container>
				<form onSubmit={handleSubmit(onSubmit)}>
					<h2 className='step-head-blue pulse text-center py-3'>Tell us about your income?</h2>
					<Row className='py-5'>
						<Col>
							<label>Who is your Employer?</label>
							<input
								type='text'
								{...register('.WhoYourEmployer')}
								onChange={(e) => setWhoYourEmployer(e.target.value)}
								value={WhoYourEmployer}
							/>
							{errors && errors.WhoYourEmployer && <span>who is your Employer is required*</span>}
						</Col>
						<Col>
							<label>What is your position or title?</label>
							<input
								type='text'
								{...register('.WhatPositionTitle')}
								onChange={(e) => setWhatPositionTitle(e.target.value)}
								value={WhatPositionTitle}
							/>
							{errors && errors.WhatPositionTitle && (
								<span>What is your position or title is required*</span>
							)}
						</Col>
						<Col>
							<label>When did you start?</label>
							<input
								type='text'
								{...register('.WhenDidYouStart')}
								onChange={(e) => setWhenDidYouStart(e.target.value)}
								value={WhenDidYouStart}
							/>
							{errors && errors.WhenDidYouStart && <span>When did you start is required*</span>}
						</Col>
					</Row>
					<Row>
						<Col>
							<label>Street Address</label>
							<input
								type='text'
								{...register('.StreetAddress')}
								onChange={(e) => setStreetAddress(e.target.value)}
								value={StreetAddress}
							/>
							{errors && errors.StreetAddress && <span>Street Address is required*</span>}
						</Col>
						<Col>
							<label>City</label>
							<input
								type='text'
								{...register('.City')}
								onChange={(e) => setCity(e.target.value)}
								value={City}
							/>
							{errors && errors.City && <span>City is required*</span>}
						</Col>
						<Col>
							<label>State</label>
							<select {...register('.State')} onChange={(e) => setState(e.target.value)} value={State}>
								<option value='AL'>Alabama</option>
								<option value='AK'>Alaska</option>
								<option value='AZ'>Arizona</option>
								<option value='AR'>Arkansas</option>
								<option value='CA'>California</option>
								<option value='CO'>Colorado</option>
								<option value='CT'>Connecticut</option>
								<option value='DE'>Delaware</option>
								<option value='DC'>District of Columbia</option>
								<option value='FL'>Florida</option>
								<option value='GA'>Georgia</option>
								<option value='HI'>Hawaii</option>
								<option value='ID'>Idaho</option>
								<option value='IL'>Illinois</option>
								<option value='IN'>Indiana</option>
								<option value='IA'>Iowa</option>
								<option value='KS'>Kansas</option>
								<option value='KY'>Kentucky</option>
								<option value='LA'>Louisiana</option>
								<option value='ME'>Maine</option>
								<option value='MD'>Maryland</option>
								<option value='MA'>Massachusetts</option>
								<option value='MI'>Michigan</option>
								<option value='MN'>Minnesota</option>
								<option value='MS'>Mississippi</option>
								<option value='MO'>Missouri</option>
								<option value='MT'>Montana</option>
								<option value='NE'>Nebraska</option>
								<option value='NV'>Nevada</option>
								<option value='NH'>New Hampshire</option>
								<option value='NJ'>New Jersey</option>
								<option value='NM'>New Mexico</option>
								<option value='NY'>New York</option>
								<option value='NC'>North Carolina</option>
								<option value='ND'>North Dakota</option>
								<option value='OH'>Ohio</option>
								<option value='OK'>Oklahoma</option>
								<option value='OR'>Oregon</option>
								<option value='PA'>Pennsylvania</option>
								<option value='RI'>Rhode Island</option>
								<option value='SC'>South Carolina</option>
								<option value='SD'>South Dakota</option>
								<option value='TN'>Tennessee</option>
								<option value='TX'>Texas</option>
								<option value='UT'>Utah</option>
								<option value='VT'>Vermont</option>
								<option value='VA'>Virginia</option>
								<option value='WA'>Washington</option>
								<option value='WV'>West Virginia</option>
								<option value='WI'>Wisconsin</option>
								<option value='WY'>Wyoming</option>
								<option value='AS'>American Samoa</option>
								<option value='GU'>Guam</option>
								<option value='MP'>Northern Mariana Islands</option>
								<option value='PR'>Puerto Rico</option>
								<option value='UM'>United States Minor Outlying Islands</option>
								<option value='VI'>Virgin Islands</option>
								<option value='NA'>Not a US City</option>
							</select>
							{errors.State && <span>State Required*</span>}
						</Col>

						<Col>
							<label>Zip*</label>
							<input
								type='text'
								{...register('.Zip')}
								onChange={(e) => setZip(e.target.value)}
								value={Zip}
							/>
							{errors && errors.Zip && <span>Zipcode is required*</span>}
						</Col>
						<Col>
							<label>Work Phone</label>
							<input
								type='text'
								{...register('.WorkPhone')}
								onChange={(e) => setWorkPhone(e.target.value)}
								value={WorkPhone}
							/>
							{errors && errors.WorkPhone && <span>Work Phone is required*</span>}
						</Col>
					</Row>

					{/* <Row>
            <Col className="py-5 text-center">
              <Link to="/dashboard/your-location" className="cl-btn"> Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}

					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/your-location' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
