import React, { useState, useEffect } from "react";
import { Default } from "react-awesome-spinners";
import {
  PDFViewer,
  Page,
  Text,
  // Link,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { redirect } from "react-router-dom";
// import { getApplication } from "./../firebase";

// import logo from "./../assets/pdf/logo.png";
import page1 from "./../assets/BankingDisclosurePDF/page1.jpg";

const BankingDisclosurePDF = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTimeout, setIsTimeout] = useState(false);
  const [obj, setObj] = useState([]);
  const [email, setEmail] = useState('')
  // const [yourJobStatusDetail, setYourJobStatusDetail] = useState([]);
  // const [yourJobStatusDetailTotal, setYourJobStatusDetailTotal] = useState(0);
  // const [YourJobExpense, setYourJobExpense] = useState(0);
  // const [YourJobExpenseTotal, setYourJobExpenseTotal] = useState(0);
  // const [cashSaving, setCashSaving] = useState([]);
  // const [cashSavingTotal, setCashSavingTotal] = useState(0);
  // const [insurancePolicy, setInsurancePolicy] = useState([]);
  // const [insurancePolicyFaceValue, setInsurancePolicyFaceValue] = useState(0);
  // const [insurancePolicyCashValue, setInsurancePolicyCashValue] = useState(0);
  // const [insurancePolicyLoans, setInsurancePolicyLoans] = useState(0);
  // const [shareBond, setShareBond] = useState(0);
  // const [shareBondPresentMarketValue, setShareBondPresentMarketValue] = useState(0);
  // const [realEstatePersonal, setRealEstatePersonal] = useState([]);
  // const [realEstatePersonalMarketValue, setRealEstatePersonalMarketValue] = useState(0);
  // const [realEstatePersonalPresentLoanBal, setRealEstatePersonalPresentLoanBal] = useState(0);
  // const [realEstatePersonalMonthlyRentalIncome, setRealEstatePersonalMonthlyRentalIncome] = useState(0);
  // const [realEstatePersonalMonthlyPayment, setRealEstatePersonalMonthlyPayment] = useState(0);
  // const [realEstateSecond, setRealEstateSecond] = useState({});
  // const [realEstateInvestment, setRealEstateInvestment] = useState([]);
  // const [realEstateInvestmentMarketValue, setRealEstateInvestmentMarketValue] = useState(0);
  // const [realEstateInvestmentPresentLoanBal, setRealEstateInvestmentPresentLoanBal] = useState(0);
  // const [realEstateInvestmentMonthlyRentalIncome, setRealEstateInvestmentMonthlyRentalIncome] = useState(0);
  // const [realEstateInvestmentMonthlyPayment, setRealEstateInvestmentMonthlyPayment] = useState(0);
  // const [realEstateInvestmentNoMarketValue, setRealEstateInvestmentNoMarketValue] = useState(0);
  // const [realEstateInvestmentNoPresentLoanBal, setRealEstateInvestmentNoPresentLoanBal] = useState(0);
  // const [realEstateInvestmentNoMonthlyRentalIncome, setRealEstateInvestmentNoMonthlyRentalIncome] = useState(0);
  // const [realEstateInvestmentNoMonthlyPayment, setRealEstateInvestmentNoMonthlyPayment] = useState(0);
  // const [mortgageContract, setMortgageContract] = useState(0);
  // const [mortgageContractBalanceDue, setMortgageContractBalanceDue] = useState(0);
  // const [profitPension, setProfitPension] = useState(0);
  // const [profitPensionAccountBalance, setProfitPensionAccountBalance] = useState(0);
  // const [profitPensionAmountTotallyVested, setProfitPensionAmountTotallyVested] = useState(0);
  // const [profitPensionLoans, setProfitPensionLoans] = useState(0);
  // const [payableLoan, setPayableLoan] = useState(0);
  // const [payableLoanMonthlyPayment, setPayableLoanMonthlyPayment] = useState(0);
  // const [payableLoanUnpaidBalance, setPayableLoanUnpaidBalance] = useState(0);
  // const [contingentLiabilities, setContingentLiabilities] = useState([]);
  // const [contingentLiabilitiesQuestionnaire, setContingentLiabilitiesQuestionnaire] = useState([]);
  // const [contingentLiabilitiesQuestionnaireOther, setContingentLiabilitiesQuestionnaireOther] = useState([]);

  // const [totalAssetsAmount, setTotalAssetsAmount] = useState(0);
  // const [subtotalLiabilitiesAmount, setSubtotalLiabilitiesAmount] = useState(0);
  // const [netWorthAmount, setNetWorthAmount] = useState(0);

  useEffect(() => {
    async function fetchPost() {

      const pdfData = localStorage.getItem("pdfData");
      const pdfDataJSON = JSON.parse(pdfData);
      const user = JSON.parse(localStorage.getItem("user"));
      setEmail(user.email)
      let x;
      let total;
      console.log({ pdfDataJSON })
      setObj(pdfDataJSON);
      // console.log("obj", obj);

      // YourJobStatusDetail
      // setYourJobStatusDetail(pdfDataJSON.YourJobStatusDetail);
      // console.log("yourJobStatusDetail", yourJobStatusDetail);

      // total = 0;
      // total = parseFloat(total) + 
      // ((yourJobStatusDetail.BonusesCommissions) ? parseFloat(yourJobStatusDetail.BonusesCommissions) : 0) + 
      // ((yourJobStatusDetail.CapitalGains) ? parseFloat(yourJobStatusDetail.CapitalGains) : 0) + 
      // ((yourJobStatusDetail.DividendIncome) ? parseFloat(yourJobStatusDetail.DividendIncome) : 0) + 
      // ((yourJobStatusDetail.InterestIncome) ? parseFloat(yourJobStatusDetail.InterestIncome) : 0) + 
      // ((yourJobStatusDetail.OtherIncome) ? parseFloat(yourJobStatusDetail.OtherIncome) : 0) + 
      // ((yourJobStatusDetail.OtherInvestmentIncome) ? parseFloat(yourJobStatusDetail.OtherInvestmentIncome) : 0) + 
      // ((yourJobStatusDetail.PartnershipIncome) ? parseFloat(yourJobStatusDetail.PartnershipIncome) : 0) + 
      // ((yourJobStatusDetail.YourIncome) ? parseFloat(yourJobStatusDetail.YourIncome) : 0);
      // total = total.toLocaleString();
      // setYourJobStatusDetailTotal(total);
      // console.log("yourJobStatusDetailTotal", yourJobStatusDetailTotal);
      // // YourJobStatusDetail

      // // YourJobExpense
      // setYourJobExpense(pdfDataJSON.YourJobExpense);
      // console.log("YourJobExpense", YourJobExpense);

      // total = 0;
      // total = parseFloat(total) + 
      // ((YourJobExpense.FederalIncomeOtherTaxes) ? parseFloat(YourJobExpense.FederalIncomeOtherTaxes) : 0) + 
      // ((YourJobExpense.StateIncomeOtherTaxes) ? parseFloat(YourJobExpense.StateIncomeOtherTaxes) : 0) + 
      // ((YourJobExpense.RentalPaymentsCoopCondoMaintenance) ? parseFloat(YourJobExpense.RentalPaymentsCoopCondoMaintenance) : 0) + 
      // ((YourJobExpense.MortgagePayments) ? parseFloat(YourJobExpense.MortgagePayments) : 0) + 
      // ((YourJobExpense.PropertyTaxes) ? parseFloat(YourJobExpense.PropertyTaxes) : 0) + 
      // ((YourJobExpense.ResidentialSchedule5) ? parseFloat(YourJobExpense.ResidentialSchedule5) : 0) + 
      // ((YourJobExpense.InvestmentSchedule5) ? parseFloat(YourJobExpense.InvestmentSchedule5) : 0) + 
      // ((YourJobExpense.Residential) ? parseFloat(YourJobExpense.Residential) : 0) + 
      // ((YourJobExpense.Investment) ? parseFloat(YourJobExpense.Investment) : 0) + 
      // ((YourJobExpense.InterestPrincipalPaymentsLoans) ? parseFloat(YourJobExpense.InterestPrincipalPaymentsLoans) : 0) + 
      // ((YourJobExpense.InsuranceIncludingAutoHomeLifeDisabilityOther) ? parseFloat(YourJobExpense.InsuranceIncludingAutoHomeLifeDisabilityOther) : 0) + 
      // ((YourJobExpense.InvestmentsIncludingTaxShelters) ? parseFloat(YourJobExpense.InvestmentsIncludingTaxShelters) : 0) + 
      // ((YourJobExpense.AlimonyChildSupport) ? parseFloat(YourJobExpense.AlimonyChildSupport) : 0) + 
      // ((YourJobExpense.Tuition) ? parseFloat(YourJobExpense.Tuition) : 0) + 
      // ((YourJobExpense.OtherLivingExpenses) ? parseFloat(YourJobExpense.OtherLivingExpenses) : 0) + 
      // ((YourJobExpense.MedicalExpenses) ? parseFloat(YourJobExpense.MedicalExpenses) : 0) + 
      // ((YourJobExpense.OtherExpenseList) ? parseFloat(YourJobExpense.OtherExpenseList) : 0);
      // total = total.toLocaleString();
      // setYourJobExpenseTotal(total);
      // console.log("YourJobExpenseTotal", YourJobExpenseTotal);
      // // YourJobExpense

      // // CashSaving
      // setCashSaving(Object.values(pdfDataJSON.CashSaving));
      // console.log("cashSaving", cashSaving);

      // total = 0; for(x=0; x<cashSaving.length; x++) { total = parseFloat(total) + ((cashSaving[x].AccountBalance) ? parseFloat(cashSaving[x].AccountBalance) : 0) } total = total.toLocaleString();
      // setCashSavingTotal(total);
      // console.log("cashSavingTotal", cashSavingTotal);
      // // CashSaving

      // // InsurancePolicy
      // setInsurancePolicy(Object.values(pdfDataJSON.InsurancePolicy));
      // console.log("insurancePolicy", insurancePolicy);

      // total = 0; for(x=0; x<insurancePolicy.length; x++) { total = parseFloat(total) + ((insurancePolicy[x].FaceValue) ? parseFloat(insurancePolicy[x].FaceValue) : 0) } total = total.toLocaleString();
      // setInsurancePolicyFaceValue(total);
      // console.log("insurancePolicyFaceValue", insurancePolicyFaceValue);

      // total = 0; for(x=0; x<insurancePolicy.length; x++) { total = parseFloat(total) + ((insurancePolicy[x].CashValue) ? parseFloat(insurancePolicy[x].CashValue) : 0) } total = total.toLocaleString();
      // setInsurancePolicyCashValue(total);
      // console.log("insurancePolicyCashValue", insurancePolicyCashValue);

      // total = 0; for(x=0; x<insurancePolicy.length; x++) { total = parseFloat(total) + ((insurancePolicy[x].Loans) ? parseFloat(insurancePolicy[x].Loans) : 0) } total = total.toLocaleString();
      // setInsurancePolicyLoans(total);
      // console.log("insurancePolicyLoans", insurancePolicyLoans);
      // // InsurancePolicy

      // // ShareBond
      // setShareBond(Object.values(pdfDataJSON.ShareBond));
      // console.log("shareBond", shareBond);

      // total = 0; for(x=0; x<shareBond.length; x++) { total = parseFloat(total) + ((shareBond[x].PresentMarketValue) ? parseFloat(shareBond[x].PresentMarketValue) : 0) } total = total.toLocaleString();
      // setShareBondPresentMarketValue(total);
      // console.log("shareBondPresentMarketValue", shareBondPresentMarketValue);
      // // ShareBond

      // // MortgageContract
      // setMortgageContract(Object.values(pdfDataJSON.MortgageContract));
      // console.log("mortgageContract", mortgageContract);

      // total = 0; for(x=0; x<mortgageContract.length; x++) { total = parseFloat(total) + ((mortgageContract[x].BalanceDue) ? parseFloat(mortgageContract[x].BalanceDue) : 0) } total = total.toLocaleString();
      // setMortgageContractBalanceDue(total);
      // console.log("mortgageContractBalanceDue", mortgageContractBalanceDue);
      // // MortgageContract

      // // RealEstatePersonal
      // setRealEstatePersonal(Object.values(pdfDataJSON.RealEstatePersonal));
      // console.log("realEstatePersonal", realEstatePersonal);

      // total = 0; for(x=0; x<realEstatePersonal.length; x++) { total = parseFloat(total) + ((realEstatePersonal[x].MarketValue) ? parseFloat(realEstatePersonal[x].MarketValue) : 0) }
      // total = parseFloat(total) + ((realEstateSecond.MarketValue) ? parseFloat(realEstateSecond.MarketValue) : 0)
      // total = total.toLocaleString();
      // setRealEstatePersonalMarketValue(total);
      // console.log("realEstatePersonalMarketValue", realEstatePersonalMarketValue);

      // total = 0; for(x=0; x<realEstatePersonal.length; x++) { total = parseFloat(total) + ((realEstatePersonal[x].PresentLoanBal) ? parseFloat(realEstatePersonal[x].PresentLoanBal) : 0) }
      // total = parseFloat(total) + ((realEstateSecond.PresentLoanBal) ? parseFloat(realEstateSecond.PresentLoanBal) : 0)
      // total = total.toLocaleString();
      // setRealEstatePersonalPresentLoanBal(total);
      // console.log("realEstatePersonalPresentLoanBal", realEstatePersonalPresentLoanBal);

      // total = 0; for(x=0; x<realEstatePersonal.length; x++) { total = parseFloat(total) + ((realEstatePersonal[x].MonthlyRentalIncome) ? parseFloat(realEstatePersonal[x].MonthlyRentalIncome) : 0) }
      // total = parseFloat(total) + ((realEstateSecond.MonthlyRentalIncome) ? parseFloat(realEstateSecond.MonthlyRentalIncome) : 0)
      // total = total.toLocaleString();
      // setRealEstatePersonalMonthlyRentalIncome(total);
      // console.log("realEstatePersonalMonthlyRentalIncome", realEstatePersonalMonthlyRentalIncome);

      // total = 0; for(x=0; x<realEstatePersonal.length; x++) { total = parseFloat(total) + ((realEstatePersonal[x].MonthlyPayment) ? parseFloat(realEstatePersonal[x].MonthlyPayment) : 0) }
      // total = parseFloat(total) + ((realEstateSecond.MonthlyPayment) ? parseFloat(realEstateSecond.MonthlyPayment) : 0)
      // total = total.toLocaleString();
      // setRealEstatePersonalMonthlyPayment(total);
      // console.log("realEstatePersonalMonthlyPayment", realEstatePersonalMonthlyPayment);
      // // RealEstatePersonal

      // // RealEstateSecond
      // setRealEstateSecond(pdfDataJSON.RealEstateSecond);
      // console.log("realEstateSecond", realEstateSecond);
      // // RealEstateSecond

      // // RealEstateInvestment
      // setRealEstateInvestment(Object.values(pdfDataJSON.RealEstateInvestment));
      // console.log("realEstateInvestment", realEstateInvestment);

      // total = 0; for(x=0; x<realEstateInvestment.length; x++) {
      //   if(realEstateInvestment[x].InvestmentOwnedSomeoneOwnership === "100") {
      //     total = parseFloat(total) + ((realEstateInvestment[x].MarketValue) ? parseFloat(realEstateInvestment[x].MarketValue) : 0)
      //   }
      // } total = total.toLocaleString();
      // setRealEstateInvestmentMarketValue(total);
      // console.log("realEstateInvestmentMarketValue", realEstateInvestmentMarketValue);

      // total = 0; for(x=0; x<realEstateInvestment.length; x++) {
      //   if(realEstateInvestment[x].InvestmentOwnedSomeoneOwnership === "100") {
      //     total = parseFloat(total) + ((realEstateInvestment[x].PresentLoanBal) ? parseFloat(realEstateInvestment[x].PresentLoanBal) : 0)
      //   }
      // } total = total.toLocaleString();
      // setRealEstateInvestmentPresentLoanBal(total);
      // console.log("realEstateInvestmentPresentLoanBal", realEstateInvestmentPresentLoanBal);

      // total = 0; for(x=0; x<realEstateInvestment.length; x++) {
      //   if(realEstateInvestment[x].InvestmentOwnedSomeoneOwnership === "100") {
      //     total = parseFloat(total) + ((realEstateInvestment[x].MonthlyRentalIncome) ? parseFloat(realEstateInvestment[x].MonthlyRentalIncome) : 0)
      //   }
      // } total = total.toLocaleString();
      // setRealEstateInvestmentMonthlyRentalIncome(total);
      // console.log("realEstateInvestmentMonthlyRentalIncome", realEstateInvestmentMonthlyRentalIncome);

      // total = 0; for(x=0; x<realEstateInvestment.length; x++) {
      //   if(realEstateInvestment[x].InvestmentOwnedSomeoneOwnership === "100") {
      //     total = parseFloat(total) + ((realEstateInvestment[x].MonthlyPayment) ? parseFloat(realEstateInvestment[x].MonthlyPayment) : 0)
      //   }
      // } total = total.toLocaleString();
      // setRealEstateInvestmentMonthlyPayment(total);
      // console.log("realEstateInvestmentMonthlyPayment", realEstateInvestmentMonthlyPayment);


      // total = 0; for(x=0; x<realEstateInvestment.length; x++) {
      //   if(realEstateInvestment[x].InvestmentOwnedSomeoneOwnership !== "100") {
      //     total = parseFloat(total) + ((realEstateInvestment[x].MarketValue) ? parseFloat(realEstateInvestment[x].MarketValue) : 0)
      //   }
      // } total = total.toLocaleString();
      // setRealEstateInvestmentNoMarketValue(total);
      // console.log("realEstateInvestmentNoMarketValue", realEstateInvestmentNoMarketValue);

      // total = 0; for(x=0; x<realEstateInvestment.length; x++) {
      //   if(realEstateInvestment[x].InvestmentOwnedSomeoneOwnership !== "100") {
      //     total = parseFloat(total) + ((realEstateInvestment[x].PresentLoanBal) ? parseFloat(realEstateInvestment[x].PresentLoanBal) : 0)
      //   }
      // } total = total.toLocaleString();
      // setRealEstateInvestmentNoPresentLoanBal(total);
      // console.log("realEstateInvestmentNoPresentLoanBal", realEstateInvestmentNoPresentLoanBal);

      // total = 0; for(x=0; x<realEstateInvestment.length; x++) {
      //   if(realEstateInvestment[x].InvestmentOwnedSomeoneOwnership !== "100") {
      //     total = parseFloat(total) + ((realEstateInvestment[x].MonthlyRentalIncome) ? parseFloat(realEstateInvestment[x].MonthlyRentalIncome) : 0)
      //   }
      // } total = total.toLocaleString();
      // setRealEstateInvestmentNoMonthlyRentalIncome(total);
      // console.log("realEstateInvestmentNoMonthlyRentalIncome", realEstateInvestmentNoMonthlyRentalIncome);

      // total = 0; for(x=0; x<realEstateInvestment.length; x++) {
      //   if(realEstateInvestment[x].InvestmentOwnedSomeoneOwnership !== "100") {
      //     total = parseFloat(total) + ((realEstateInvestment[x].MonthlyPayment) ? parseFloat(realEstateInvestment[x].MonthlyPayment) : 0)
      //   }
      // } total = total.toLocaleString();
      // setRealEstateInvestmentNoMonthlyPayment(total);
      // console.log("realEstateInvestmentNoMonthlyPayment", realEstateInvestmentNoMonthlyPayment);
      // // RealEstateInvestment

      // // ProfitPension
      // setProfitPension(Object.values(pdfDataJSON.ProfitPension));
      // console.log("profitPension", profitPension);

      // total = 0; for(x=0; x<profitPension.length; x++) { total = parseFloat(total) + ((profitPension[x].AccountBalance) ? parseFloat(profitPension[x].AccountBalance) : 0) } total = total.toLocaleString();
      // setProfitPensionAccountBalance(total);
      // console.log("profitPensionAccountBalance", profitPensionAccountBalance);

      // total = 0; for(x=0; x<profitPension.length; x++) { total = parseFloat(total) + ((profitPension[x].AmountTotallyVested) ? parseFloat(profitPension[x].AmountTotallyVested) : 0) } total = total.toLocaleString();
      // setProfitPensionAmountTotallyVested(total);
      // console.log("profitPensionAmountTotallyVested", profitPensionAmountTotallyVested);

      // total = 0; for(x=0; x<profitPension.length; x++) { total = parseFloat(total) + ((profitPension[x].Loans) ? parseFloat(profitPension[x].Loans) : 0) } total = total.toLocaleString();
      // setProfitPensionLoans(total);
      // console.log("profitPensionLoans", profitPensionLoans);
      // // ProfitPension

      // // PayableLoan
      // setPayableLoan(Object.values(pdfDataJSON.PayableLoan));
      // console.log("payableLoan", payableLoan);

      // total = 0; for(x=0; x<payableLoan.length; x++) { total = parseFloat(total) + ((payableLoan[x].MonthlyPayment) ? parseFloat(payableLoan[x].MonthlyPayment) : 0) } total = total.toLocaleString();
      // setPayableLoanMonthlyPayment(total);
      // console.log("payableLoanMonthlyPayment", payableLoanMonthlyPayment);

      // total = 0; for(x=0; x<payableLoan.length; x++) { total = parseFloat(total) + ((payableLoan[x].UnpaidBalance) ? parseFloat(payableLoan[x].UnpaidBalance) : 0) } total = total.toLocaleString();
      // setPayableLoanUnpaidBalance(total);
      // console.log("payableLoanUnpaidBalance", payableLoanUnpaidBalance);
      // // PayableLoan

      // // ContingentLiabilities
      // setContingentLiabilities(Object.values(pdfDataJSON.ContingentLiabilities));
      // console.log("contingentLiabilities", contingentLiabilities);
      // // ContingentLiabilities

      // // ContingentLiabilitiesQuestionnaire
      // setContingentLiabilitiesQuestionnaire(pdfDataJSON.ContingentLiabilitiesQuestionnaire);
      // console.log("contingentLiabilitiesQuestionnaire", contingentLiabilitiesQuestionnaire);
      // // ContingentLiabilitiesQuestionnaire

      // // ContingentLiabilitiesQuestionnaireOther
      // setContingentLiabilitiesQuestionnaireOther(pdfDataJSON.ContingentLiabilitiesQuestionnaireOther);
      // console.log("contingentLiabilitiesQuestionnaireOther", contingentLiabilitiesQuestionnaireOther);
      // // ContingentLiabilitiesQuestionnaireOther

      // // totalAssetsAmount // subtotalLiabilitiesAmount // netWorthAmount
      // total = 0;
      // total = parseFloat(total) + 
      // ((obj.CompleteForm.valueCompaniesAmount) ? parseFloat(obj.CompleteForm.valueCompaniesAmount) : 0) + 
      // ((obj.CompleteForm.automobilesAmount) ? parseFloat(obj.CompleteForm.automobilesAmount) : 0) + 
      // ((obj.CompleteForm.personalPropertyAmount) ? parseFloat(obj.CompleteForm.personalPropertyAmount) : 0) + 
      // ((obj.CompleteForm.otherTangibleAssetsAmount) ? parseFloat(obj.CompleteForm.otherTangibleAssetsAmount) : 0) + 
      // ((obj.CompleteForm.shortTermNotesAmount) ? parseFloat(obj.CompleteForm.shortTermNotesAmount) : 0) + 
      // ((obj.CompleteForm.installmentLoansContractsAmount) ? parseFloat(obj.CompleteForm.installmentLoansContractsAmount) : 0) + 
      // ((obj.CompleteForm.taxesFederalStateAmount) ? parseFloat(obj.CompleteForm.taxesFederalStateAmount) : 0) + 
      // ((obj.CompleteForm.loansAgainstRetirementAmount) ? parseFloat(obj.CompleteForm.loansAgainstRetirementAmount) : 0) + 
      // ((obj.CompleteForm.otherLiabilitiesAmount) ? parseFloat(obj.CompleteForm.otherLiabilitiesAmount) : 0);      
      // // total = total.toLocaleString();
      // setTotalAssetsAmount(total);
      // console.log("totalAssetsAmount", totalAssetsAmount);

      // total = 0;
      // total = parseFloat(total) + 
      // ((obj.CompleteForm.valueCompaniesAmount) ? parseFloat(obj.CompleteForm.valueCompaniesAmount) : 0) + 
      // ((obj.CompleteForm.automobilesAmount) ? parseFloat(obj.CompleteForm.automobilesAmount) : 0) + 
      // ((obj.CompleteForm.personalPropertyAmount) ? parseFloat(obj.CompleteForm.personalPropertyAmount) : 0) + 
      // ((obj.CompleteForm.otherTangibleAssetsAmount) ? parseFloat(obj.CompleteForm.otherTangibleAssetsAmount) : 0) + 
      // ((obj.CompleteForm.shortTermNotesAmount) ? parseFloat(obj.CompleteForm.shortTermNotesAmount) : 0);
      // // total = total.toLocaleString();
      // setSubtotalLiabilitiesAmount(total);
      // console.log("subtotalLiabilitiesAmount", subtotalLiabilitiesAmount);

      // total = 0;
      // total = parseFloat(total) + 
      // ((totalAssetsAmount) ? parseFloat(totalAssetsAmount) : 0) - 
      // ((subtotalLiabilitiesAmount) ? parseFloat(subtotalLiabilitiesAmount) : 0); 
      // total = total.toLocaleString();
      // setNetWorthAmount(total);
      // console.log("netWorthAmount", netWorthAmount);
      // totalAssetsAmount // subtotalLiabilitiesAmount // netWorthAmount

      setTimeout(() => {
        setIsLoading(false);
        setIsTimeout(true);
      }, 500);
    }

    fetchPost();

  }, [obj.length]);

  if (isLoading || !isTimeout) {
    console.log("isLoading", isLoading);
    console.log("isTimeout", isTimeout);
    return (
      <div>
        <div style={styles.Ring}>
          <Default />
        </div>
      </div>
    );
  } else {
    console.log("isLoading", isLoading);
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.body}>
            {/*
            <View style={styles.header} fixed><Image style={styles.logo} src={logo} /></View>
            */}

            <Image style={styles.page1_img} src={page1} />

            <Text style={[styles.text, { position: "absolute", top: "640px", left: "100px" }]}>{new Date().toDateString()}</Text>

            <Text style={[styles.text, { position: "absolute", top: "673px", left: "150px" }]}>{email}</Text>

          </Page>

        </Document>
      </PDFViewer>
    );
  }
};

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  page1_img: {
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    // alignItems: "baseline",
    // alignContent: "baseline",
  },
  col: {
    margin: 0,
  },
  item: {
    fontFamily: "Poppins",
    fontSize: 10,
    color: "#000",
  },

  txtLeft: {
    textAlign: "left",
  },
  txtRight: {
    textAlign: "right",
  },
  txtCenter: {
    textAlign: "center",
  },

  Border: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#DAE6ED",
  },
  BorderTop: {
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#DAE6ED",
  },
  BorderRight: {
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: "#DAE6ED",
  },
  BorderBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#DAE6ED",
  },
  BorderLeft: {
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: "#DAE6ED",
  },
  BorderNo: {
    border: 0,
  },

  width16: {
    width: "16.5%",
  },
  width25: {
    width: "25%",
  },
  width30: {
    width: "30%",
  },
  width33: {
    width: "33.33%",
  },
  width35: {
    width: "35%",
  },
  width50: {
    width: "50%",
  },
  width65: {
    width: "65%",
  },
  width75: {
    width: "75%",
  },
  width80: {
    width: "80%",
  },
  width90: {
    width: "90%",
  },
  width100: {
    width: "100%",
  },

  text: {
    margin: 1.8,
    fontSize: 9,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  applicant_co_applicant: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '165px',
    left: '35px',
  },
  CompleteForm: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '377px',
    left: '35px',
  },
  CompleteForm2: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '377px',
    left: '300px',
  },
  YourJobStatusDetail: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '92px',
    left: '252px',
    width: '45px',
  },
  YourJobExpense: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '92px',
    left: '515px',
    width: '45px',
  },
  CashSaving: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '338px',
    left: '35px',
  },
  InsurancePolicy: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '586px',
    left: '35px',
  },
  ShareBond: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '457px',
    left: '35px',
  },
  MortgageContract: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '72px',
    left: '35px',
  },
  RealEstatePersonal: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '158px',
    left: '35px',
  },
  RealEstateInvestment: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '205px',
    left: '35px',
  },
  RealEstateInvestmentNo: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '279px',
    left: '35px',
  },
  ProfitPension: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '376px',
    left: '35px',
  },
  PayableLoan: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '484px',
    left: '35px',
  },
  ContingentLiabilities: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '93px',
    left: '35px',
  },
  ContingentLiabilitiesQuestionnaire: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '143px',
    left: '366px',
  },
  ContingentLiabilitiesQuestionnaireOther: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '229px',
    left: '338px',
  },
  ApplicantTotalEstimatedTaxLiabilityInformation: {
    // backgroundColor: 'green',
    // opacity: 0.5,
    position: 'absolute',
    top: '285px',
    left: '35px',
    padding: 2,
  },

});

export default BankingDisclosurePDF;
