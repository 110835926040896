import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const RealEstatePersonal = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [arr, setArr] = useState([
		/*{
      LegalOwner: "",
      PropertyAddress: "",
      PurchasePrice: "",
      PurchaseYear: "",
      MarketValue: "",
      PresentLoanBal: "",
      InterestRate: "",
      LoanMaturityDate: "",
      MonthlyRentalIncome: "",
      MonthlyPayment: "",
      Lender: "",
    }*/
	]);
	const [fullNameAYS, setFullNameAYS] = useState('');
	const [StreetAddress, setStreetAddress] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		// Convert arr to the format of data. Using arr here becuase data is raw and arr is sanitized
		// arr is sanitized in updateInput function
		const saveData = {};
		arr.map((item, i) => {
			saveData[i] = item;
		});
		console.log({ saveData });

		await updateApplication(createApplicationDefault, { RealEstatePersonal: saveData });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/real-estate-investment-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setArr(Object.values(getApplicationDefault.RealEstatePersonal));
		setFullNameAYS(getApplicationDefault.AboutYourSelf.FullName);
		setStreetAddress(getApplicationDefault.YourLocation.StreetAddress);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	if (fullNameAYS && StreetAddress) {
		arr[0]['LegalOwner'] = arr[0]['LegalOwner'] === '' ? fullNameAYS : arr[0]['LegalOwner'];
		arr[0]['PropertyAddress'] = arr[0]['PropertyAddress'] === '' ? StreetAddress : arr[0]['PropertyAddress'];

		setTimeout(() => {
			setArr(arr);
		}, 500);
	}

	const addInput = () => {
		setArr((s) => {
			return [
				...s,
				[
					{
						LegalOwner: '',
						PropertyAddress: '',
						PurchasePrice: '',
						PurchaseYear: '',
						MarketValue: '',
						PresentLoanBal: '',
						InterestRate: '',
						LoanMaturityDate: '',
						MonthlyRentalIncome: '',
						MonthlyPayment: '',
						Lender: '',
					},
				],
			];
		});
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		// return value
	};

	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const updateInput = (evnt, index, ele) => {
		const { name, value } = evnt.target;
		const list = [...arr];
		if (
			ele === 'PurchasePrice' ||
			ele === 'MarketValue' ||
			ele === 'PresentLoanBal' ||
			ele === 'MonthlyRentalIncome' ||
			ele === 'MonthlyPayment'
		)
			list[index][ele] = sanitizeValue(value);
		else list[index][ele] = value;
		setArr(list);
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell me about your personal home</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{arr.map((item, i) => {
						return (
							<Row key={i}>
								<Row className='py-3'>
									<Col xs={3}>
										<label>Legal Owner</label>
										<input
											value={item.LegalOwner}
											type='text'
											{...register(i + '.LegalOwner')}
											onChange={(e) => updateInput(e, i, 'LegalOwner')}
										/>
									</Col>
									<Col xs={6}>
										<label>Property Address</label>
										<input
											value={item.PropertyAddress}
											type='text'
											{...register(i + '.PropertyAddress')}
											onChange={(e) => updateInput(e, i, 'PropertyAddress')}
										/>
									</Col>
									<Col>
										<label>Purchase Price</label>
										<input
											value={parseCurrency(item.PurchasePrice)}
											type='text'
											{...register(i + '.PurchasePrice')}
											onChange={(e) => updateInput(e, i, 'PurchasePrice')}
											placeholder='0'
										/>
									</Col>
									<Col>
										<label>Purchase Year</label>
										<input
											value={item.PurchaseYear}
											type='text'
											{...register(i + '.PurchaseYear')}
											onChange={(e) => updateInput(e, i, 'PurchaseYear')}
											maxlength='2'
										/>
									</Col>
								</Row>
								<Row className='py-3'>
									<Col>
										<label>Market Value</label>
										<input
											value={parseCurrency(item.MarketValue)}
											type='text'
											{...register(i + '.MarketValue')}
											onChange={(e) => updateInput(e, i, 'MarketValue')}
										/>
									</Col>
									<Col>
										<label>Present Loan Bal. $</label>
										<input
											value={parseCurrency(item.PresentLoanBal)}
											type='text'
											{...register(i + '.PresentLoanBal')}
											onChange={(e) => updateInput(e, i, 'PresentLoanBal')}
										/>
									</Col>
									<Col>
										<label>Interest Rate</label>
										<input
											value={item.InterestRate}
											type='text'
											{...register(i + '.InterestRate')}
											onChange={(e) => updateInput(e, i, 'InterestRate')}
										/>
									</Col>
									<Col>
										<label>Loan Maturity Date</label>
										<input
											value={item.LoanMaturityDate}
											type='text'
											{...register(i + '.LoanMaturityDate')}
											onChange={(e) => updateInput(e, i, 'LoanMaturityDate')}
										/>
									</Col>
								</Row>
								<Row className='py-3 justify-content-md-center'>
									<Col xs={3}>
										<label>Monthly Rental Income</label>
										<input
											value={parseCurrency(item.MonthlyRentalIncome)}
											type='text'
											{...register(i + '.MonthlyRentalIncome')}
											onChange={(e) => updateInput(e, i, 'MonthlyRentalIncome')}
										/>
									</Col>
									<Col xs={3}>
										<label xs={3}>Monthly Payment(s)</label>
										<input
											value={parseCurrency(item.MonthlyPayment)}
											type='text'
											{...register(i + '.MonthlyPayment')}
											onChange={(e) => updateInput(e, i, 'MonthlyPayment')}
										/>
									</Col>
									<Col xs={3}>
										<label>Lender</label>
										<input
											value={item.Lender}
											type='text'
											{...register(i + '.Lender')}
											onChange={(e) => updateInput(e, i, 'Lender')}
										/>
									</Col>
								</Row>
							</Row>
						);
					})}

					<Row className='py-5 justify-content-md-center'>
						<Col xs={3} className='text-center '>
							<div onClick={addInput} className='cl-btn'>
								+
							</div>
						</Col>
					</Row>

					{/*<Row>
            <Col className="text-center py-5">
              <Link to="/dashboard/real-estate-personal-flow" className="btn-outline-primary mr-2">Back</Link>
              <button className="cl-btn">Submit & Continue</button>
            </Col>
          </Row> */}

					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/real-estate-personal-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
