import React, { useState } from 'react';
import './Dashboard.css';
import { Header } from './dashboard/Header';
import { Outlet, Route, Routes } from 'react-router-dom';
import Intro from './dashboard/forms/Intro';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { sendEmail } from '../firebase';

import Icon from '../assets/chat.png';

function Dashboard() {
	const callSupport = () => {};
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	return (
		<div>
			<Header setShow={setShow} />
			<Outlet />
			<button
				type='button'
				data-bs-toggle='modal'
				data-bs-target='#exampleModal'
				className='support-button'
				onClick={() => setShow(true)}></button>
			{/* Form */}
			<Modal size='lg' show={show} onHide={handleClose} aria-labelledby='contained-modal-title-vcenter' centered>
				<Modal.Header className='text-center'>
					<Modal.Title className='w-100'>Hello! Let Us Know How We Can Help You</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form setShow={setShow} />
				</Modal.Body>
			</Modal>
		</div>
	);
}
const Form = ({ setShow }) => {
	const [form, setForm] = useState({ name: '', email: '', subject: '', info: '', image: '' });
	const [error, setError] = useState({ name: '', email: '', subject: '', info: '', image: '' });
	// const [isSubmitting, setIsSubmitting] = useState(false);
	const onSubmit = async (e) => {
		e.preventDefault();
		setError(validate(form));
		// setIsSubmitting(true);
		// if (Object.keys(error).length === 0) {
		// 	sendEmail(form).then((res) => {
		// 		if (res.status) {
		// 			setShow(false);
		// 			alert('Email sent successfully!');
		// 		} else {
		// 			alert('Failed to send email.');
		// 		}
		// 	});
		// }
		try {
			const res = await sendEmail(form);
			if (res.status) {
				setShow(false);
				alert('Email sent successfully!');
			} else {
				alert('Failed to send email.');
			}
		} catch (err) {
			console.log(err);
		}
	};
	const validate = (form) => {
		let error = {};
		if (!form.name) error.name = 'Name is required';

		if (!form.email) error.email = 'Email is required';
		else if (!/\S+@\S+\.\S+/.test(form.email)) error.email = 'Email is invalid';

		if (!form.subject) error.subject = 'Subject is required';
		if (!form.info) error.info = 'Info is required';
		if (!form.image) error.image = 'Image is required';
		return error;
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setForm({ ...form, [name]: value });
		setError({ ...error, [name]: '' });
	};

	const inputStyles = {
		fontSize: '16px',
		padding: '10px 15px',
		border: '1px solid #ccc',
		borderRadius: '5px',
		outline: 'none',
		transition: 'border-color 0.2s ease',
	};
	return (
		<div>
			<form onSubmit={onSubmit} className='px-4'>
				<Row className='form-group py-3 justify-content-md-center'>
					<label htmlFor='name'>Name</label>
					<input
						type='text'
						name='name'
						id='name'
						placeholder='Enter your name'
						value={form.name}
						style={inputStyles}
						onChange={handleChange}
					/>
					{error.name && <span>{error.name}</span>}
				</Row>
				<Row className='form-group py-3 justify-content-md-center'>
					<label htmlFor='email'>Email</label>
					<input
						type='email'
						name='email'
						id='email'
						style={inputStyles}
						placeholder='Enter your email'
						value={form.email}
						onChange={handleChange}
					/>
					{error.email && <span>{error.email}</span>}
				</Row>
				<Row className='form-group py-3 justify-content-md-center'>
					<label htmlFor='subject'>Subject</label>
					<input
						type='text'
						name='subject'
						id='subject'
						style={inputStyles}
						placeholder='Enter your subject'
						value={form.subject}
						onChange={handleChange}
					/>
					{error.subject && <span>{error.subject}</span>}
				</Row>
				<Row className='form-group py-3 justify-content-md-center'>
					<label htmlFor='info'>Info</label>
					<textarea
						name='info'
						id='info'
						cols='30'
						rows='3'
						style={inputStyles}
						placeholder='Describe the Bug in Detai'
						value={form.info}
						onChange={handleChange}></textarea>
					{error.info && <span>{error.info}</span>}
				</Row>
				<Row className='form-group py-3 justify-content-md-center'>
					<label htmlFor='image'>Attach Image </label>
					<input
						type='file'
						name='image'
						id='image'
						style={inputStyles}
						placeholder='Enter your image'
						value={form.image}
						onChange={(e) => setForm({ ...form, image: e.target.files[0] })}
					/>
					{error.image && <span>{error.image}</span>}
				</Row>
				<div className='form-group d-flex py-3 justify-content-end gap-5'>
					<button
						type='button'
						className='btn btn-danger '
						onClick={() => setShow(false)}
						style={{ fontWeight: '500', fontSize: '1rem' }}>
						Cancel
					</button>
					<button
						type='submit'
						className='btn'
						style={{ background: '#82bdc5', color: '#fff', fontWeight: '500', fontSize: '1rem' }}>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};

export default Dashboard;
