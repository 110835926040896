import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const AboutYourSelf = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [fullNameAYS, setFullNameAYS] = useState('');
	const [socialSecurityAYS, setSocialSecurityAYS] = useState('');
	const [dateOfBirthAYS, setDateOfBirthAYS] = useState('');
	const [statusAYS, setStatusAYS] = useState('');
	const [dependentsAYS, setDependentsAYS] = useState('');
	const [childrenAYS, setChildrenAYS] = useState('');
	const [transactionTypeApply, setTransactionTypeApply] = useState('');

	const [dependentsAgeAYS, setDependentsAgeAYS] = useState([]);
	const [childrenAgeAYS, setChildrenAgeAYS] = useState([]);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		// console.log({ data });
		const storeData = {
			FullName: fullNameAYS,
			SocialSecurity: socialSecurityAYS,
			DateOfBirth: dateOfBirthAYS,
			Status: statusAYS,
			Dependents: dependentsAYS,
			dependentsAgeAYS: dependentsAgeAYS,
		};

		// console.log("updateApplication id, data", createApplicationDefault, data);
		const res = await updateApplication(createApplicationDefault, { AboutYourSelf: storeData });
		// console.log({ res })
		// Firebase Store, Create new Record and Store Data

		if (transactionTypeApply === 'yes') {
			navigate('/dashboard/your-location');
		} else {
			navigate('/dashboard/your-location');
		}
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });

		setFullNameAYS(getApplicationDefault.AboutYourSelf.FullName);
		setSocialSecurityAYS(getApplicationDefault.AboutYourSelf.SocialSecurity);
		setDateOfBirthAYS(getApplicationDefault.AboutYourSelf.DateOfBirth);
		setStatusAYS(getApplicationDefault.AboutYourSelf.Status);
		setDependentsAYS(getApplicationDefault.AboutYourSelf.Dependents);
		setChildrenAYS(getApplicationDefault.AboutYourSelf.Children);
		setTransactionTypeApply(getApplicationDefault.TransactionType.TransactionTypeApply);
		setDependentsAgeAYS(Object.values(getApplicationDefault.AboutYourSelf.dependentsAgeAYS));
		setChildrenAgeAYS(Object.values(getApplicationDefault.AboutYourSelf.childrenAgeAYS));
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const addDependentsAgeAYS = () => {
		setDependentsAgeAYS((s) => {
			return [...s, ['']];
		});
	};

	const addChildrenAgeAYS = () => {
		setChildrenAgeAYS((s) => {
			return [...s, ['']];
		});
	};

	const updateDependentsAgeAYS = (evnt, index) => {
		const { value } = evnt.target;
		const list = [...dependentsAgeAYS];
		list[index] = value;
		setDependentsAgeAYS(list);
	};

	const removeDependentsAgeAYS = (index) => {
		setDependentsAgeAYS(dependentsAgeAYS.filter((item, i) => i !== index));
	};

	const updateChildrenAgeAYS = (evnt, index) => {
		const { value } = evnt.target;
		const list = [...childrenAgeAYS];
		list[index] = value;
		setChildrenAgeAYS(list);
	};

	const removeChildrenAgeAYS = (index) => {
		setChildrenAgeAYS(childrenAgeAYS.filter((item, i) => i !== index));
	};

	return (
		<div className='body-bg py-3'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell us little about yourself</h2>
				<p className='text-center py-3'>
					Please provide accurate information; this information is used to create your application.
				</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row className='py-3'>
						<Col>
							<label>Full Name</label>
							<input
								type='text'
								{...register('.FullName')}
								onChange={(e) => setFullNameAYS(e.target.value)}
								value={fullNameAYS}
								defaultValue={fullNameAYS}
							/>
							{errors && errors.FullName && <span>Full Name is required*</span>}
						</Col>
						<Col>
							<label>Social Security #</label>
							<input
								type='text'
								{...register('.SocialSecurity')}
								onChange={(e) => {
									const value = e.target.value.replace(/\D/g, '');
									const formattedValue = value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
									setSocialSecurityAYS(formattedValue);
								}}
								value={socialSecurityAYS}
								placeholder='000-00-0000'
								maxLength={11}
							/>
							{errors && errors['.SocialSecurity'] && (
								<p style={{ color: 'red' }}>{errors['.SocialSecurity'].message}</p>
							)}
						</Col>
						<Col>
							<label>Date of Birth</label>
							<input
								type='date'
								{...register('.DateOfBirth')}
								onChange={(e) => setDateOfBirthAYS(e.target.value)}
								value={dateOfBirthAYS}
							/>
							{errors && errors.DateOfBirth && <span>Date of Birth # is required*</span>}
						</Col>
						<Col xs={4}>
							<label>Marital Status</label>
							{['radio'].map((type) => (
								<div key={`inline-${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Unmarried'
										value='unmarried'
										type={type}
										id={`inline-${type}-1`}
										{...register('.Status')}
										onChange={(e) => setStatusAYS(e.target.value)}
										checked={statusAYS === 'unmarried'}
									/>
									<Form.Check
										inline
										label='Married'
										value='married'
										type={type}
										id={`inline-${type}-2`}
										{...register('.Status')}
										onChange={(e) => setStatusAYS(e.target.value)}
										checked={statusAYS === 'married'}
									/>
									<Form.Check
										inline
										label='Separated'
										value='separated'
										type={type}
										id={`inline-${type}-3`}
										{...register('.Status')}
										onChange={(e) => setStatusAYS(e.target.value)}
										checked={statusAYS === 'separated'}
									/>
								</div>
							))}
							{errors && errors.Status && <span>Marital Status Required</span>}
						</Col>
					</Row>
					<Row className='justify-content-md-center'>
						<Col xs={12}>
							<label>How many dependents do you have?</label>
							<input
								placeholder='ex: 2'
								type='text'
								{...register('.Dependents')}
								onChange={(e) => setDependentsAYS(e.target.value)}
								value={dependentsAYS}
							/>
							<br />
							{dependentsAgeAYS.map((item, i) => {
								return (
									<Row key={i} className='justify-content-md-center'>
										<Col className='position-relative'>
											<label>Age of dependents?</label>
											<input
												placeholder='ex: 16'
												type='text'
												{...register('.dependentsAgeAYS[' + i + ']')}
												onChange={(e) => updateDependentsAgeAYS(e, i)}
												value={dependentsAgeAYS[i]}
											/>
											{/*
                      <div className="removeItem" onClick={(e) => { removeDependentsAgeAYS(i) }}>X</div>
                      */}
										</Col>
									</Row>
								);
							})}
							<br />
							<Row className='justify-content-md-center'>
								<Col xs={2} className='text-center'>
									<div onClick={addDependentsAgeAYS} className='cl-btn'>
										+
									</div>
								</Col>
							</Row>
						</Col>
						{/*
            <Col xs={6}>
              <label>How many children do you have?</label>
              <input placeholder="ex: 2" type="text" {...register(".Children")} onChange={(e) => setChildrenAYS(e.target.value)} value={childrenAYS} />
              <br/>
              {childrenAgeAYS.map((item, i) => {
                return (
                  <Row className="justify-content-md-center">
                    <Col className="position-relative">
                      <label>Age of children?</label>
                      <input placeholder="ex: 16" type="text" {...register(".childrenAgeAYS["+i+"]")} onChange={(e) => updateChildrenAgeAYS(e, i)} value={childrenAgeAYS[i]} />
                      <div className="removeItem" onClick={(e) => { removeChildrenAgeAYS(i) }}>X</div>
                    </Col>
                  </Row>
                );
              })}
              <br/>
              <Row className="justify-content-md-center">
                <Col xs={2} className="text-center">
                  <div onClick={addChildrenAgeAYS} className="cl-btn">+</div>
                </Col>
              </Row>
            </Col>
            */}
					</Row>

					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/transactiontype' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
