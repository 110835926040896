import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const ShareBond = () => {
	const {
		register,
		handleSubmit,
		// watch,
		// formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [arr, setArr] = useState([]);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		// Convert arr to the format of data. Using arr here becuase data is raw and arr is sanitized
		// arr is sanitized in updateInput function
		const saveData = {};
		arr.map((item, i) => {
			saveData[i] = item;
		});
		console.log({ saveData });

		await updateApplication(createApplicationDefault, { ShareBond: saveData });
		// Firebase Store, Create new Record and Store Data
		console.log({ gg: await getApplication(createApplicationDefault) });
		const getApplicationDefault = await getApplication(createApplicationDefault);
		const coApplicantExistence = getApplicationDefault?.TransactionType?.TransactionTypeApply;
		const nextRoute =
			coApplicantExistence === 'yes' ? '/dashboard/about-your-co-Aplicant' : '/dashboard/cash-saving';
		navigate(nextRoute);
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setArr(Object.values(getApplicationDefault.ShareBond));
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};
	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const addInput = () => {
		setArr((s) => {
			return [
				...s,
				[
					{
						SharesBondAmount: '',
						Description: '',
						WhoseNameRegistered: '',
						group: '',
						Cost: '',
						PresentMarketValue: '',
						ExchangeListedUnlisted: '',
						ControlledRestricted: '',
					},
				],
			];
		});
	};

	const updateInput = (evnt, index, ele) => {
		const { value } = evnt.target;
		const list = [...arr];
		if (ele === 'SharesBondAmount' || ele === 'Cost' || ele === 'PresentMarketValue')
			list[index][ele] = sanitizeValue(value);
		else list[index][ele] = value;
		setArr(list);
	};

	const removeInput = (index) => {
		setArr(arr.filter((item, i) => i !== index));
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>
					Tell us about your Cash Bonds and Market Shares...
				</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{arr.map((item, i) => {
						return (
							<Row key={i} className='position-relative'>
								<Row className='py-5 mb'>
									<Col>
										<label>Number of Shares or Bond Amount</label>
										<input
											type='text'
											{...register(i + '.SharesBondAmount')}
											onChange={(e) => updateInput(e, i, 'SharesBondAmount')}
											value={parseCurrency(item.SharesBondAmount)}
										/>
									</Col>
									<Col xs={4}>
										<label>Description</label>
										<input
											type='text'
											{...register(i + '.Description')}
											onChange={(e) => updateInput(e, i, 'Description')}
											value={item.Description}
										/>
									</Col>
									<Col>
										<label>In Whose Name(s) Registered?</label>
										<input
											type='text'
											{...register(i + '.WhoseNameRegistered')}
											onChange={(e) => updateInput(e, i, 'WhoseNameRegistered')}
											value={item.WhoseNameRegistered}
										/>
									</Col>
									<Col>
										<label>Pledged?</label>
										{['radio'].map((type, index) => (
											<div key={`group_${i}_${index}`} className='mb-3 py-3'>
												<Form.Check
													inline
													label='Yes'
													type={type}
													id={`group1_${i}_${index}`}
													value='Yes'
													{...register(`${i}.group`)}
													onChange={(e) => updateInput(e, i, 'group')}
													defaultChecked={item.group === 'Yes'}
												/>
												<Form.Check
													inline
													label='No'
													type={type}
													id={`group2_${i}_${index}`}
													value='No'
													{...register(`${i}.group`)}
													onChange={(e) => updateInput(e, i, 'group')}
													defaultChecked={item.group === 'No'}
												/>
											</div>
										))}
									</Col>
								</Row>
								<Row className='mb'>
									<Col>
										<label>Cost ($)</label>
										<input
											type='text'
											{...register(i + '.Cost')}
											onChange={(e) => updateInput(e, i, 'Cost')}
											value={parseCurrency(item.Cost)}
											placeholder='0'
										/>
									</Col>
									<Col xs={4}>
										<label>Present Market Value ($)</label>
										<input
											type='text'
											{...register(i + '.PresentMarketValue')}
											onChange={(e) => updateInput(e, i, 'PresentMarketValue')}
											value={parseCurrency(item.PresentMarketValue)}
											placeholder='0'
										/>
									</Col>
									<Col>
										<label>Exchange Listed-L Unlisted-U</label>
										{['radio'].map((type, index) => (
											<div key={`ExchangeListedUnlisted_${i}_${index}`} className='mb-3 py-3'>
												<Form.Check
													inline
													label='Listed'
													type={type}
													id={`ExchangeListedUnlisted1_${i}_${index}`}
													value='L'
													{...register(i + '.ExchangeListedUnlisted')}
													onChange={(e) => updateInput(e, i, 'ExchangeListedUnlisted')}
													defaultChecked={item.ExchangeListedUnlisted === 'L'}
												/>
												<Form.Check
													inline
													label='Unlisted'
													type={type}
													id={`ExchangeListedUnlisted2_${i}_${index}`}
													value='U'
													{...register(i + '.ExchangeListedUnlisted')}
													onChange={(e) => updateInput(e, i, 'ExchangeListedUnlisted')}
													defaultChecked={item.ExchangeListedUnlisted === 'U'}
												/>
											</div>
										))}
									</Col>
									<Col>
										<label>Controlled-C Restricted-R</label>
										{['radio'].map((type, index) => (
											<div key={`ControlledRestricted_${i}_${index}`} className='mb-3 py-3'>
												<Form.Check
													inline
													label='Controlled'
													type={type}
													id={`ControlledRestricted1_${i}_${index}`}
													value='C'
													{...register(i + '.ControlledRestricted')}
													onChange={(e) => updateInput(e, i, 'ControlledRestricted')}
													defaultChecked={item.ControlledRestricted === 'C'}
												/>
												<Form.Check
													inline
													label='Restricted'
													type={type}
													id={`ControlledRestricted2_${i}_${index}`}
													value='R'
													{...register(i + '.ControlledRestricted')}
													onChange={(e) => updateInput(e, i, 'ControlledRestricted')}
													defaultChecked={item.ControlledRestricted === 'R'}
												/>
											</div>
										))}
									</Col>
								</Row>
								<Row>
									<Col>
										<div
											className='removeItem'
											onClick={(e) => {
												removeInput(i);
											}}>
											X
										</div>
									</Col>
								</Row>
							</Row>
						);
					})}

					<Row className='py-5 justify-content-md-center'>
						<Col xs={3} className='text-center '>
							<div onClick={addInput} className='cl-btn'>
								+
							</div>
						</Col>
					</Row>

					{/* <Row>
            <Col className="text-center">
              <Link to="/dashboard/share-bond-flow" className="cl-btn">Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}
					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/share-bond-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
