import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, logInWithEmailAndPassword, signInWithGoogle } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import './Login.css';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();
	const [user, loading] = useAuthState(auth);

	if (user) navigate('/dashboard');

	useEffect(() => {
		if (loading) {
			return;
		} // maybe trigger a loading screen
		if (user) {
			navigate('/dashboard');
		}
	}, [user, loading]);

	return (
		<div className='login login__bg'>
			<div className='login__wrap'>
				<h1 className='login__title'>Login to your account</h1>
				<div className='login__form'>
					<div className='login__form__field'>
						<label>EMAIL</label>
						<input
							type='email'
							className='login__textBox'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder='example@mail.com'
						/>
					</div>
					<div className='login__form__field'>
						<label>PASSWORD</label>
						<input
							type='password'
							className='login__textBox'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder='Password'
						/>
						<div className='login__form__field__link'>
							<Link to='/reset'>Forget password?</Link>
						</div>
					</div>
					<div className='login__form__field'>
						<button className='login__btn' onClick={() => logInWithEmailAndPassword(email, password)}>
							LOGIN NOW
						</button>
						<button className='login__btn login__google' onClick={signInWithGoogle}>
							LOGIN NOW GOOGLE
						</button>
					</div>
					<div className='login__form__field login__form__field__or'>Or</div>
					<div className='login__form__field'>
						<button className='login__btn login__register' onClick={() => navigate('/register')}>
							Create new account
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
