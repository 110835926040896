import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const AboutYourCoAplicant = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [fullNameAYCA, setFullNameAYCA] = useState('');
	const [socialSecurityAYCA, setSocialSecurityAYCA] = useState('');
	const [dateOfBirthAYCA, setDateOfBirthAYCA] = useState('');
	const [statusAYCA, setStatusAYCA] = useState('');

	const [dependentsAYS, setDependentsAYS] = useState('');
	const [dependentsAYCA, setDependentsAYCA] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		const dataSave = {
			DateOfBirth: dateOfBirthAYCA,
			FullName: fullNameAYCA,
			SocialSecurity: socialSecurityAYCA,
			Status: statusAYCA,
			dependents: dependentsAYS,
			DependentAges: dependentsAYCA,
		};

		updateApplication(createApplicationDefault, { AboutYourCoAplicant: dataSave });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/co-aplicant-location');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });

		setFullNameAYCA(getApplicationDefault.AboutYourCoAplicant.FullName);
		setSocialSecurityAYCA(getApplicationDefault.AboutYourCoAplicant.SocialSecurity);
		setDateOfBirthAYCA(getApplicationDefault.AboutYourCoAplicant.DateOfBirth);
		setStatusAYCA(getApplicationDefault.AboutYourCoAplicant.Status);

		setDependentsAYS(getApplicationDefault.AboutYourCoAplicant.dependents);
		setDependentsAYCA(
			getApplicationDefault.AboutYourCoAplicant.DependentAges &&
				Array.isArray(getApplicationDefault.AboutYourCoAplicant.DependentAges)
				? getApplicationDefault.AboutYourCoAplicant.DependentAges
				: ['']
		);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const addDependentsAYCA = () => {
		setDependentsAYCA((s) => {
			return [...s, ['']];
		});
	};

	const updateDependentsAYCA = (evnt, index) => {
		const { value } = evnt.target;
		const list = [...dependentsAYCA];
		list[index] = value;
		setDependentsAYCA(list);
	};

	const removeDependentsAYCA = (index) => {
		setDependentsAYCA(dependentsAYCA.filter((item, i) => i !== index));
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell us about your co-applicant</h2>
				<p className='text-center py-3'>
					Please provide accurate information, this information is used to create your application.
				</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row className='py-3'>
						<Col>
							<label>Full Name</label>
							<input
								type='text'
								{...register('.FullName')}
								onChange={(e) => setFullNameAYCA(e.target.value)}
								value={fullNameAYCA}
							/>
							{errors && errors.FullName && <span>Full Name is required</span>}
						</Col>
						<Col>
							<label>Social Security #</label>
							<input
								type='text'
								{...register('.SocialSecurity')}
								onChange={(e) => {
									const value = e.target.value.replace(/\D/g, '');
									const formattedValue = value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
									setSocialSecurityAYCA(formattedValue);
								}}
								value={socialSecurityAYCA}
								placeholder='000-00-0000'
								maxLength={11}
							/>
							{errors && errors['.SocialSecurity'] && (
								<p style={{ color: 'red' }}>{errors['.SocialSecurity'].message}</p>
							)}
						</Col>
						<Col>
							<label>Date of Birth</label>
							<input
								type='date'
								{...register('.DateOfBirth')}
								onChange={(e) => setDateOfBirthAYCA(e.target.value)}
								value={dateOfBirthAYCA}
							/>
							{errors && errors.DateOfBirth && <span>Date of Birth # is required</span>}
						</Col>
						<Col xs={4}>
							<label>Marital Status</label>
							{['radio'].map((type) => (
								<div key={`inline-${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Unmarried'
										value='unmarried'
										type={type}
										id={`inline-${type}-1`}
										{...register('.Status')}
										onChange={(e) => setStatusAYCA(e.target.value)}
										checked={statusAYCA == 'unmarried'}
									/>
									<Form.Check
										inline
										label='Married'
										value='married'
										type={type}
										id={`inline-${type}-2`}
										{...register('.Status')}
										onChange={(e) => setStatusAYCA(e.target.value)}
										checked={statusAYCA == 'married'}
									/>
									<Form.Check
										inline
										label='Separated'
										value='separated'
										type={type}
										id={`inline-${type}-3`}
										{...register('.Status')}
										onChange={(e) => setStatusAYCA(e.target.value)}
										checked={statusAYCA == 'separated'}
									/>
								</div>
							))}
							{errors && errors.Status && <span>Marital Status Required</span>}
						</Col>
					</Row>
					<Row className='justify-content-md-center'>
						<Col>
							<label>How many dependents do you have?</label>
							<input
								placeholder='ex: 2'
								type='text'
								{...register('.dependents')}
								onChange={(e) => setDependentsAYS(e.target.value)}
								value={dependentsAYS}
							/>
							<br />
							{dependentsAYCA && Array.isArray(dependentsAYCA) && dependentsAYCA.length > 0
								? dependentsAYCA.map((item, i) => {
										return (
											<Row key={i} className='justify-content-md-center'>
												<Col className='position-relative'>
													<label>Age of dependents?</label>
													<input
														placeholder='ex: 16'
														type='text'
														{...register('.DependentAges[' + i + ']')}
														onChange={(e) => updateDependentsAYCA(e, i)}
														value={dependentsAYCA[i]}
													/>
													<div
														className='removeItem'
														onClick={(e) => {
															removeDependentsAYCA(i);
														}}>
														X
													</div>
												</Col>
											</Row>
										);
								  })
								: ''}
							<br />
							<Row className='justify-content-md-center'>
								<Col xs={2} className='text-center'>
									<div onClick={addDependentsAYCA} className='cl-btn'>
										+
									</div>
								</Col>
							</Row>
						</Col>
					</Row>

					{/* <Row className="py-5 justify-content-md-center">
            <Col xs={3} className="text-center">
              <Link to="/dashboard/share-bond" className="cl-btn">Back</Link>
              <input className="cl-btn" type="submit" value="Save & Continue" />
            </Col>
          </Row> */}
					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/share-bond' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
