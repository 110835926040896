import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Form from "react-bootstrap/Form";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const CoApplicantJobStatus = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [employerCAJS, setEmployerCAJS] = useState('');
	const [positionCAJS, setPositionCAJS] = useState('');
	const [startCAJS, setStartCAJS] = useState('');
	const [streetAddressCAJS, setStreetAddressCAJS] = useState('');
	const [cityCAJS, setCityCAJS] = useState('');
	const [stateCAJS, setStateCAJS] = useState('');
	const [zipCAJS, setZipCAJS] = useState('');
	const [phoneCAJS, setPhoneCAJS] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		console.log({ data });
		const dataSave = {
			employer: employerCAJS,
			position: positionCAJS,
			start: startCAJS,
			streetAddress: streetAddressCAJS,
			city: cityCAJS,
			state: stateCAJS,
			zip: zipCAJS,
			phone: phoneCAJS,
		};

		updateApplication(createApplicationDefault, { CoApplicantJobStatus: dataSave });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/cash-saving');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });

		setEmployerCAJS(getApplicationDefault.CoApplicantJobStatus.employer);
		setPositionCAJS(getApplicationDefault.CoApplicantJobStatus.position);
		setStartCAJS(getApplicationDefault.CoApplicantJobStatus.start);
		setStreetAddressCAJS(getApplicationDefault.CoApplicantJobStatus.streetAddress);
		setCityCAJS(getApplicationDefault.CoApplicantJobStatus.city);
		setStateCAJS(getApplicationDefault.CoApplicantJobStatus.state);
		setZipCAJS(getApplicationDefault.CoApplicantJobStatus.zip);
		setPhoneCAJS(getApplicationDefault.CoApplicantJobStatus.phone);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Is your co-applicant working?</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row className='py-5'>
						<Col>
							<label>Who is your Employer?</label>
							<input
								type='text'
								{...register('.employer')}
								onChange={(e) => setEmployerCAJS(e.target.value)}
								value={employerCAJS}
							/>
							{errors && errors.employer && <span>who is your Employer is required</span>}
						</Col>
						<Col>
							<label>What is your position or title?</label>
							<input
								type='text'
								{...register('.position')}
								onChange={(e) => setPositionCAJS(e.target.value)}
								value={positionCAJS}
							/>
							{errors && errors.position && <span>What is your position or title is required</span>}
						</Col>
						<Col>
							<label>When did you start?</label>
							<input
								type='text'
								{...register('.start')}
								onChange={(e) => setStartCAJS(e.target.value)}
								value={startCAJS}
							/>
							{errors && errors.start && <span>When did you start is required</span>}
						</Col>
					</Row>
					<Row>
						<Col>
							<label>Work Address</label>
							<input
								type='text'
								{...register('.streetAddress')}
								onChange={(e) => setStreetAddressCAJS(e.target.value)}
								value={streetAddressCAJS}
							/>
							{errors && errors.streetAddress && <span>Work Address is required</span>}
						</Col>
						<Col>
							<label>City</label>
							<input
								type='text'
								{...register('.city')}
								onChange={(e) => setCityCAJS(e.target.value)}
								value={cityCAJS}
							/>
							{errors && errors.city && <span>City is required</span>}
						</Col>
						<Col>
							<label>State</label>
							<select
								{...register('state')}
								onChange={(e) => setStateCAJS(e.target.value)}
								value={stateCAJS}>
								<option value='AL'>Alabama</option>
								<option value='AK'>Alaska</option>
								<option value='AZ'>Arizona</option>
								<option value='AR'>Arkansas</option>
								<option value='CA'>California</option>
								<option value='CO'>Colorado</option>
								<option value='CT'>Connecticut</option>
								<option value='DE'>Delaware</option>
								<option value='DC'>District of Columbia</option>
								<option value='FL'>Florida</option>
								<option value='GA'>Georgia</option>
								<option value='HI'>Hawaii</option>
								<option value='ID'>Idaho</option>
								<option value='IL'>Illinois</option>
								<option value='IN'>Indiana</option>
								<option value='IA'>Iowa</option>
								<option value='KS'>Kansas</option>
								<option value='KY'>Kentucky</option>
								<option value='LA'>Louisiana</option>
								<option value='ME'>Maine</option>
								<option value='MD'>Maryland</option>
								<option value='MA'>Massachusetts</option>
								<option value='MI'>Michigan</option>
								<option value='MN'>Minnesota</option>
								<option value='MS'>Mississippi</option>
								<option value='MO'>Missouri</option>
								<option value='MT'>Montana</option>
								<option value='NE'>Nebraska</option>
								<option value='NV'>Nevada</option>
								<option value='NH'>New Hampshire</option>
								<option value='NJ'>New Jersey</option>
								<option value='NM'>New Mexico</option>
								<option value='NY'>New York</option>
								<option value='NC'>North Carolina</option>
								<option value='ND'>North Dakota</option>
								<option value='OH'>Ohio</option>
								<option value='OK'>Oklahoma</option>
								<option value='OR'>Oregon</option>
								<option value='PA'>Pennsylvania</option>
								<option value='RI'>Rhode Island</option>
								<option value='SC'>South Carolina</option>
								<option value='SD'>South Dakota</option>
								<option value='TN'>Tennessee</option>
								<option value='TX'>Texas</option>
								<option value='UT'>Utah</option>
								<option value='VT'>Vermont</option>
								<option value='VA'>Virginia</option>
								<option value='WA'>Washington</option>
								<option value='WV'>West Virginia</option>
								<option value='WI'>Wisconsin</option>
								<option value='WY'>Wyoming</option>
								<option value='AS'>American Samoa</option>
								<option value='GU'>Guam</option>
								<option value='MP'>Northern Mariana Islands</option>
								<option value='PR'>Puerto Rico</option>
								<option value='UM'>United States Minor Outlying Islands</option>
								<option value='VI'>Virgin Islands</option>
								<option value='NA'>Not a US City</option>
							</select>
							{errors && errors.state && <span>State is Required*</span>}
						</Col>

						<Col>
							<label>Zip</label>
							<input
								type='text'
								{...register('.zip')}
								onChange={(e) => setZipCAJS(e.target.value)}
								value={zipCAJS}
							/>
							{errors && errors.zip && <span>Zipcode is required</span>}
						</Col>
						<Col>
							<label>Work Phone</label>
							<input
								type='text'
								{...register('.phone')}
								onChange={(e) => setPhoneCAJS(e.target.value)}
								value={phoneCAJS}
							/>
							{errors && errors.phone && <span>Work Phone is required</span>}
						</Col>
					</Row>

					{/* <Row>
            <Col className="py-5 text-center">
              <Link to="/dashboard/co-aplicant-location" className="cl-btn"> Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}
					<Row className='py-5 justify-content-between flex-row-reverse'>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
						<Col xs={5} md={3}>
							<Link to='/dashboard/co-aplicant-location' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
